import { FilterFormValues } from 'components/filter-bar';
import { IntlKeys, LangCode } from 'localization';
import { useIntl } from 'react-intl';
import { ResourcesResponse } from '../lib/http';
import { SortConfigType } from './common';

export enum UserRoles {
  BackofficeAdmin = 'BackofficeAdmin',
  BackofficeSuperAdmin = 'BackofficeSuperAdmin',
  //
  ScormEmployee = 'ScormEmployee',
  //
  WorkspaceEmployee = 'WorkspaceEmployee',
  WorkspaceManager = 'WorkspaceManager',
  WorkspaceAdmin = 'WorkspaceAdmin',
  //
  Coach = 'Coach',
}

export enum UserStatus {
  All = '',
  Active = 'active',
  Archived = 'archived',
  Invited = 'invited',
}
export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  language: LangCode;
  jobTitle: string;
  companyName: string;
  avatar: string | null;
  createdAt: string;
  updatedAt: string;
  role: UserRoles;
  workspaceId: null | number;
  fullName: string;
  newRecordsCount?: number;
  reviewedRecordsCount?: number;
  progress?: number;
  status?: UserStatus;
  sessionCount?: number;
  canSaveRecord?: boolean;
};
export type InvitedUserType = {
  createdAt: string;
  id: number;
  invitedBy: { id: number; firstName: string; lastName: string; email: string };
  invitedById: 35;
  invitedUserEmail: string;
  invitedUserRole: UserRoles;
  status: UserStatus;
  teams: [{ id: number; name: string; emoji: string; workspaceId: number; creatorId: number }];
  token: string;
  updatedAt: string;
  workspace: { id: number; name: string; type: string; numberOfEmployees: number; status: string; logo: string };
  workspaceId: 1;
};

export type UsersResponse = ResourcesResponse<UserType>;
export type InvitedUsersResponse = ResourcesResponse<InvitedUserType>;

export type CreateUserType = {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  jobTitle?: string;
  timezone?: string;
  language?: LangCode;
  teamIds?: number[];
  sessionCount?: number;
};

export const uSortConfigs = {
  firstNameAsc: {
    field: 'userName',
    order: 'ASC',
  },
  firstNameDesc: {
    field: 'userName',
    order: 'DESC',
  },
  progressAsc: {
    field: 'progress',
    order: 'ASC',
  },
  progressDesc: {
    field: 'progress',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
  activityAsc: {
    field: 'activity',
    order: 'ASC',
  },
  activityDesc: {
    field: 'activity',
    order: 'DESC',
  },
  sessionsAsc: {
    field: 'sessionActivity',
    order: 'ASC',
  },
  sessionsDesc: {
    field: 'sessionActivity',
    order: 'DESC',
  },
};

export type SortOptionsWithLabels = {
  label: string;
  value: Partial<keyof typeof uSortConfigs>;
};

export const UserSortWithLabels = (): SortOptionsWithLabels[] => {
  const { formatMessage } = useIntl();

  return [
    {
      value: 'firstNameAsc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberNameAtoZ }),
    },
    {
      value: 'firstNameDesc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberNameZtoA }),
    },
    {
      value: 'progressAsc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberProgressAsc }),
    },
    {
      value: 'progressDesc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberProgressDesc }),
    },
    {
      value: 'activityAsc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberNewRecords }),
    },
    {
      value: 'activityDesc',
      label: formatMessage({ id: IntlKeys.teamsMemeberMemberOldRecords }),
    },
  ];
};

export const UserSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type UserFiltersFormValues = FilterFormValues<keyof typeof UserSortConfigs>;
export type UserListRequestParams = {
  sort: keyof typeof UserSortConfigs;
  filters: Record<'search' | string, string>;
};
