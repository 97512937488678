import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CommentType, CommentPayloadType } from 'types/slides/contract';
import { SlideTaskTypeEnum } from 'types/slides';
//
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { uploadFile } from 'portal/workspace/lib/uploadFile';
import { createApiCall } from 'lib/http/utils/createApiCall';
//
import {
  CreateRecordParams,
  CreateSpeechAnaliseParams,
  RecordAnalysis,
  RecordAnalyseResponseType,
  RecordsResponse,
  RecordType,
  CreateQuizAnswersPayload,
  CreateQuizAnswersResponse,
  BEFacialAnalyses,
} from './entities';
import { serializeWordAnalyses } from './serializers';
//

export const fetchRecordsListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<RecordsResponse>({
    api: workspaceRestClient.getRecords,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const fetchCoachRecordsListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<RecordsResponse>({
    api: workspaceRestClient.getCoachRecords,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const fetchRecordUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<RecordsResponse>({
    api: workspaceRestClient.getRecordUserList,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const fetchUserRecordsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<RecordsResponse>({
    api: workspaceRestClient.getUserRecordPitches,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getRecordsAction = createAsyncThunk<RecordsResponse, Params | undefined>(
  'records/getRecords',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordsResponse>({
      api: workspaceRestClient.getRecords,
    });

    try {
      const { data } = await callApi(requestPayload);

      return {
        ...data,
        data: [...data.data],
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeRecordsRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('records/changeRequestParams');

export const getRecordAction = createAsyncThunk<RecordType, Params>(
  'records/getRecord',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordType>({
      api: workspaceRestClient.getRecord,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getRecordAnalyses = createAsyncThunk<RecordAnalysis, CreateSpeechAnaliseParams>(
  'records/getRecordAnalyses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordAnalyseResponseType>({
      api: workspaceRestClient.getRecordAnalyses,
    });

    try {
      const { data } = await callApi(requestPayload);

      return { ...data, wordAnalyses: serializeWordAnalyses(data.wordAnalyses) };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBEFacialResultsAction = createAsyncThunk<BEFacialAnalyses, Params>(
  'records/getBEFacialResultsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BEFacialAnalyses>({
      api: workspaceRestClient.getBEFacialAnalysesResults,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getTextAnalyses = createAsyncThunk<RecordAnalysis, { text: string; slideId: number }>(
  'records/getRecordAnalyses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordAnalyseResponseType>({
      api: workspaceRestClient.getRecordAnalyses,
    });
    const { text, ...restPayload } = requestPayload;

    try {
      const { data } = await callApi({
        recordText: text,
        ...restPayload,
      });

      return { ...data, wordAnalyses: serializeWordAnalyses(data.wordAnalyses) };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createRecordAction = createAsyncThunk<RecordType, CreateRecordParams>(
  'records/createRecord',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordType>({
      api: workspaceRestClient.createRecord,
    });

    const { taskType, recordText, videoResult, audioResult } = requestPayload;

    try {
      const fileUrl =
        taskType !== SlideTaskTypeEnum.Text &&
        (await uploadFile({
          file: taskType === SlideTaskTypeEnum.Audio ? audioResult! : videoResult!,
          name: 'record',
        }));

      const payload = {
        ...(fileUrl && { recordUrl: fileUrl }),
        ...(recordText && { recordText }),
      };

      const { data } = await callApi({ ...requestPayload, ...payload });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type CreateCommentParams = CommentPayloadType & {
  params: { id: number };
};

export const createCommentAction = createAsyncThunk<CommentPayloadType, CreateCommentParams>(
  'reviews/createComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentPayloadType>({
      api: workspaceRestClient.createComment,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCommentsAction = createAsyncThunk<CommentType[], Params>(
  'records/getRecordComments',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType[]>({
      api: workspaceRestClient.getRecordComments,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      console.log('error', err);

      return rejectWithValue(err);
    }
  },
);

export const deleteCommentAction = createAsyncThunk<CommentType, Params>(
  'records/deleteComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType>({
      api: workspaceRestClient.deleteComment,
    });

    try {
      const { recordId, recordCommentId } = requestPayload;

      const { data } = await callApi({
        params: {
          recordId: recordId,
          recordCommentId: recordCommentId,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCommentAction = createAsyncThunk<CommentType, Params>(
  'records/updateComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType>({
      api: workspaceRestClient.updateComment,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createQuizAnswersAction = createAsyncThunk<CreateQuizAnswersResponse, CreateQuizAnswersPayload>(
  'records/createQuizAnswers',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CreateQuizAnswersResponse>({
      api: workspaceRestClient.createQuizAnswers,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCoachRecordAction = createAsyncThunk<RecordType, Params>(
  'records/getCoachRecord',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<RecordType>({
      api: workspaceRestClient.getCoachRecord,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchCoachRecordUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<RecordsResponse>({
    api: workspaceRestClient.getCoachRecordUserList,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const createCoachCommentAction = createAsyncThunk<CommentPayloadType, CreateCommentParams>(
  'reviews/createCoachComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentPayloadType>({
      api: workspaceRestClient.createCoachComment,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCoachCommentsAction = createAsyncThunk<CommentType[], Params>(
  'records/getCoachComments',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType[]>({
      api: workspaceRestClient.getCoachRecordComments,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      console.log('error', err);

      return rejectWithValue(err);
    }
  },
);

export const deleteCoachCommentAction = createAsyncThunk<CommentType, Params>(
  'records/deleteCoachComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType>({
      api: workspaceRestClient.deleteCoachComment,
    });

    try {
      const { recordId, recordCommentId } = requestPayload;

      const { data } = await callApi({
        params: {
          recordId: recordId,
          recordCommentId: recordCommentId,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCoachCommentAction = createAsyncThunk<CommentType, Params>(
  'records/updateCoachComment',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CommentType>({
      api: workspaceRestClient.updateCoachComment,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
