import { SlideType } from './slides';

import { TagType, CourseType } from './courses';
import { UserType } from 'types/users';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { SortConfigType } from './common';
import { FilterFormValues } from 'components/filter-bar';

export enum PitchStatusEnum {
  draft = 'draft',
  published = 'published',
}

export type PitchType = {
  id: number;
  courseId: number;
  name: string;
  description: string;
  cover: string | null;
  createdAt: string;
  updatedAt: string;
  authorId: number;
  slides: SlideType[];
  status: PitchStatusEnum;
  tags: TagType[];
  author: UserType | null;
  course: CourseType;
  isFavorite: boolean;
  isCompleted: boolean;
};

export type PitchResponse = PitchType & {
  courseId: string;
};

export enum PitchActionEnum {
  Details = 'Details',
  Training = 'Training',
  TeamTraining = 'TeamTraining',
  Update = 'Update',
  Delete = 'Delete',
  Copy = 'Copy',
  CopyToSameCourse = 'CopyToSameCourse',
  Export = 'Export',
  TestTraining = 'TestTraining',
  ExportIframe = 'ExportIframe',
}

const uSortConfigs = {
  pitchNameAsc: {
    field: 'pitchName',
    order: 'ASC',
  },
  pitchNameDesc: {
    field: 'pitchName',
    order: 'DESC',
  },
  authorNameAsc: {
    field: 'authorName',
    order: 'ASC',
  },
  authorNameDesc: {
    field: 'authorName',
    order: 'DESC',
  },
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
};

export const PitchesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type PitchFiltersFormValues = FilterFormValues<keyof typeof PitchesSortConfigs>;

export const PitchSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.pitchesPitchNameAToZ }),
      value: 'pitchNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.pitchesPitchNameZToA }),
      value: 'pitchNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesAuthorNameAToZ }),
      value: 'authorNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesAuthorNameZToA }),
      value: 'authorNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesLastUpdates }),
      value: 'updatedAtDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesOldUpdates }),
      value: 'updatedAtAsc',
    },
  ];
};

export interface IframeExportResponse {
  name: string;
  cover: string | null;
  link: string;
}
