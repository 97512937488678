import React from 'react';
import { FormattedMessage } from 'react-intl';
// types
import { UserRoles, UserType } from 'types/users';
import { WorkspaceType } from 'types/workspaces';
// utils
import { IntlKeys } from 'localization/keys';
import { currentPortalType, PortalTypes } from 'utils/portalCheck';
import { GenericChildRoute } from 'routes';
// styles
import { ReactComponent as DashboardOutlined } from 'assets/icons/dashboard_outlined.svg';
import { ReactComponent as DashboardFilled } from 'assets/icons/dashboard_filled.svg';
import { ReactComponent as CoursesOutlined } from 'assets/icons/courses_outlined.svg';
import { ReactComponent as CoursesFilled } from 'assets/icons/courses_filled.svg';
import { ReactComponent as FavouritesOutlined } from 'assets/icons/favourites_outlined.svg';
import { ReactComponent as FavouritesFilled } from 'assets/icons/favourites_filled.svg';
import { ReactComponent as TeamsOutlined } from 'assets/icons/teams_outlined.svg';
import { ReactComponent as TeamsFilled } from 'assets/icons/teams_filled.svg';
import { ReactComponent as ContentOutlined } from 'assets/icons/list_outlined.svg';
import { ReactComponent as ContentFilled } from 'assets/icons/list_filled.svg';
import { ReactComponent as WorkspaceOutlined } from 'assets/icons/workspace_outlined.svg';
import { ReactComponent as WorkspaceFilled } from 'assets/icons/workspace_filled.svg';
import { ReactComponent as UpdatesOutlined } from 'assets/icons/community_outlined.svg';
import { ReactComponent as UpdatesFilled } from 'assets/icons/community_filled.svg';
import { ReactComponent as SettingsOutlined } from 'assets/icons/settings_outlined.svg';
import { ReactComponent as SettingsFilled } from 'assets/icons/settings_filled.svg';
import { ReactComponent as CompaniesOutlined } from 'assets/icons/companies_outlined.svg';
import { ReactComponent as CompaniesFilled } from 'assets/icons/companies_filled.svg';
import { ReactComponent as UserOutlined } from 'assets/icons/user_outlined.svg';
import { ReactComponent as UserFilled } from 'assets/icons/user_filled.svg';
import { ReactComponent as CoachesOutlined } from 'assets/icons/megaphone_outlined.svg';
import { ReactComponent as CoachesFilled } from 'assets/icons/megaphone_filled.svg';

interface NavigationItem {
  path: string;
  routeKey?: string;
  //
  text: JSX.Element;
  Icon: React.ReactNode;
  IconActive: React.ReactNode;
  hide?: boolean;
}

type UseSidebarItemsConfig = {
  routes: GenericChildRoute[];
  user?: UserType;
  selectedWorkspace?: WorkspaceType;
  checkRouteAllowed: (routes: GenericChildRoute) => boolean;
  getUrlWithParams: (
    key: string,
    {
      query,
      ...params
    }: Record<string, unknown> & {
      query?: Record<string, unknown> | undefined;
    },
  ) => string;
};

export const useSidebarItems = ({
  routes,
  user,
  checkRouteAllowed,
  selectedWorkspace,
  getUrlWithParams,
}: UseSidebarItemsConfig) => {
  const mainNav = getMainNavigationItems(user, selectedWorkspace);

  const unrestrictedMainItems = mainNav.reduce<NavigationItem[]>((acc, item) => {
    const { routeKey } = item;
    const relatedRoute = routes.find(({ key }) => key === routeKey);

    if (relatedRoute && checkRouteAllowed(relatedRoute)) {
      acc.push({
        ...item,
        path: selectedWorkspace
          ? getUrlWithParams(relatedRoute.key, { workspaceId: selectedWorkspace.id })
          : relatedRoute.path,
      });
    }

    return acc;
  }, []);

  return {
    mainNavigation: unrestrictedMainItems,
    additionalNavigation: additionalNavigationItems,
  };
};

type NavItemType = Omit<NavigationItem, 'path'>;
const mainNavigationItems: Record<PortalTypes, NavItemType[]> = {
  [PortalTypes.Coach]: [
    {
      routeKey: 'home',
      Icon: <DashboardOutlined />,
      IconActive: <DashboardFilled />,
      text: <FormattedMessage id={IntlKeys.dashboard} />,
    },
    {
      routeKey: 'courseList',
      Icon: <CoursesOutlined />,
      IconActive: <CoursesFilled />,
      text: <FormattedMessage id={IntlKeys.courses} />,
    },
    {
      routeKey: 'workspaces',
      Icon: <WorkspaceOutlined />,
      IconActive: <WorkspaceFilled />,
      text: <FormattedMessage id={IntlKeys.workspaces} />,
    },
    {
      routeKey: 'coachRecords',
      Icon: <UpdatesOutlined />,
      IconActive: <UpdatesFilled />,
      text: <FormattedMessage id={IntlKeys.records} />,
    },
  ],
  [PortalTypes.Company]: [
    {
      routeKey: 'workspaceDetails',
      Icon: <DashboardOutlined />,
      IconActive: <DashboardFilled />,
      text: <FormattedMessage id={IntlKeys.dashboard} />,
    },
    {
      routeKey: 'workspaceUserList',
      Icon: <UserOutlined />,
      IconActive: <UserFilled />,
      text: <FormattedMessage id={IntlKeys.users} />,
    },
    {
      routeKey: 'workspaceTeamList',
      Icon: <TeamsOutlined />,
      IconActive: <TeamsFilled />,
      text: <FormattedMessage id={IntlKeys.teams} />,
    },
    {
      routeKey: 'boundedCourseList',
      Icon: <CoursesOutlined />,
      IconActive: <CoursesFilled />,
      text: <FormattedMessage id={IntlKeys.boundCourses} />,
    },
  ],
  [PortalTypes.Workspace]: [
    {
      routeKey: 'home',
      Icon: <DashboardOutlined />,
      IconActive: <DashboardFilled />,
      text: <FormattedMessage id={IntlKeys.dashboard} />,
    },
    {
      routeKey: 'courseList',
      Icon: <CoursesOutlined />,
      IconActive: <CoursesFilled />,
      text: <FormattedMessage id={IntlKeys.courses} />,
    },
    {
      routeKey: 'favorites',
      Icon: <FavouritesOutlined />,
      IconActive: <FavouritesFilled />,
      text: <FormattedMessage id={IntlKeys.favourites} />,
    },
    {
      routeKey: 'teamList',
      Icon: <TeamsOutlined />,
      IconActive: <TeamsFilled />,
      text: <FormattedMessage id={IntlKeys.teams} />,
    },
    {
      routeKey: 'updates',
      Icon: <UpdatesOutlined />,
      IconActive: <UpdatesFilled />,
      text: <FormattedMessage id={IntlKeys.updates} />,
    },
    {
      routeKey: 'content',
      Icon: <ContentOutlined />,
      IconActive: <ContentFilled />,
      text: <FormattedMessage id={IntlKeys.content} />,
    },
    {
      routeKey: 'workspace',
      Icon: <WorkspaceOutlined />,
      IconActive: <WorkspaceFilled />,
      text: <FormattedMessage id={IntlKeys.workspace} />,
    },
  ],
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  [PortalTypes.Backoffice]: [
    {
      routeKey: 'workspaceList',
      Icon: <CompaniesOutlined />,
      IconActive: <CompaniesFilled />,
      text: <FormattedMessage id={IntlKeys.workspaces} />,
    },
    {
      routeKey: 'content',
      Icon: <ContentOutlined />,
      IconActive: <ContentFilled />,
      text: <FormattedMessage id={IntlKeys.content} />,
    },
    {
      routeKey: 'courseList',
      Icon: <CoursesOutlined />,
      IconActive: <CoursesFilled />,
      text: <FormattedMessage id={IntlKeys.courses} />,
    },
    {
      routeKey: 'companies',
      Icon: <CoachesOutlined />,
      IconActive: <CoachesFilled />,
      text: <FormattedMessage id={IntlKeys.companies} />,
    },
  ],
  [PortalTypes.Scorm]: [],
};

const additionalNavigationItems = [
  {
    path: '/settings',
    Icon: <SettingsOutlined />,
    IconActive: <SettingsFilled />,
    text: <FormattedMessage id={IntlKeys.settings} />,
  },
  // {
  //   path: '/help',
  //   Icon: <HelpOutlined />,
  //   text: <FormattedMessage id={IntlKeys.help} />,
  // },
];

const getMainNavigationItems = (user?: UserType, selectedWorkspace?: WorkspaceType): NavItemType[] => {
  const userRole = user?.role;

  switch (userRole) {
    case UserRoles.Coach: {
      if (selectedWorkspace) {
        return mainNavigationItems[PortalTypes.Company];
      }
      return mainNavigationItems[PortalTypes.Coach];
    }

    default: {
      return mainNavigationItems[currentPortalType];
    }
  }
};
