import { createSelector } from '@reduxjs/toolkit';
//
import { RootState } from '../store';

const recordsSelector = (state: RootState) => state.records;
export const recordsMetaSelector = createSelector(recordsSelector, (records) => records.meta);

export const recordsPaginationSelector = createSelector(recordsSelector, (records) => records.pagination);
export const recordsFiltersSelector = createSelector(recordsSelector, (records) => records.filters);
export const recordsSortSelector = createSelector(recordsSelector, (records) => records.sort);

export const recordsResourcesSelector = createSelector(recordsSelector, (records) => records.resources);
export const recordsResourceSelector = createSelector(recordsSelector, (records) => records.resource);

export const recordAnaliseSelector = createSelector(recordsSelector, (records) => records.recordAnalise);
export const beyondEmotionAnalysisSelector = createSelector(recordsSelector, (records) => records.bEFacialAnalyses);

export const recordCommentsSelector = createSelector(recordsSelector, (records) => records.comments);
export const recordsPerSlideSelector = createSelector(recordsSelector, (records) => records.records);
