import { createAsyncThunk } from '@reduxjs/toolkit';
// utils
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// local
import { ReviewResponse } from './entities';
import { ReviewPayloadType } from '../records/entities';

export const fetchReviewsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<ReviewResponse>({
    api: workspaceRestClient.getReviews,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getReviewsAction = createAsyncThunk<ReviewResponse, Params | undefined>(
  'reviews/getReviews',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchReviewsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createReviewAction = createAsyncThunk<ReviewResponse, ReviewPayloadType>(
  'reviews/createReview',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ReviewResponse>({
      api: workspaceRestClient.createReview,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type ReviewPayloadTypeWithParams = ReviewPayloadType & {
  params: {
    id: number;
  };
};

export const updateReviewAction = createAsyncThunk<ReviewResponse, ReviewPayloadTypeWithParams>(
  'reviews/updateReview',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ReviewResponse>({
      api: workspaceRestClient.updateReview,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchCoachReviewsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<ReviewResponse>({
    api: workspaceRestClient.getCoachReviews,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const createCoachReviewAction = createAsyncThunk<ReviewResponse, ReviewPayloadType>(
  'reviews/createCoachReview',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ReviewResponse>({
      api: workspaceRestClient.createCoachReview,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCoachReviewAction = createAsyncThunk<ReviewResponse, ReviewPayloadTypeWithParams>(
  'reviews/updateCoachReview',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ReviewResponse>({
      api: workspaceRestClient.updateCoachReview,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
