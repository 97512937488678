import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
// utils
import { getUrlWithParams } from 'portal/workspace/routes';
import { useBreadcrumb } from 'portal/workspace/hooks/useBreadcrumb';
import useQueryParams from 'hooks/useQueryParams';
import { useIsMobile } from 'hooks/useIsMobile';
import { NotificationActionRouteEnumMappings } from 'portal/workspace/lib/navigation/entities';
// components
import { AppBar, Avatar, AppBarProps, Toolbar } from '@mui/material';
import { DynamicContentContainer, HeaderContext } from 'components/layouts/common/dynamic-header';
import NotificationPopOver from 'components/notification';
// domain
import { authUserAvatarSelector, authUserSelector } from 'portal/workspace/domain/auth/selectors';
import { currentRouteSelector } from 'portal/workspace/domain/router/reducer';
// types
import { UserRoles } from 'types/users';
// Local
import LanguagePicker from '../LanguagePicker';
// styles
import { useStyles } from './styles';
import cx from 'clsx';
// assets
import { ReactComponent as ChevronLeft } from 'assets/icons/chevronLeft.svg';
import logo from 'assets/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-dash.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
interface OwnProps {
  position?: AppBarProps['position'];
  onMenuClick?: () => void;
  isMenuOpen?: boolean;
}
const DynamicHeader: React.FC<OwnProps> = ({ position = 'relative', onMenuClick, isMenuOpen }) => {
  const params = useParams();
  const queryParams = useQueryParams();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  //
  const { title, parent, key: routeKey } = useSelector(currentRouteSelector);
  const user = useSelector(authUserSelector);
  const avatar = useSelector(authUserAvatarSelector);
  //
  const { isCustomContent } = useContext(HeaderContext);
  //
  const { breadcrumbs, removeBreadcrumb } = useBreadcrumb(params, queryParams);
  const isMobile = useIsMobile();
  const isCoach = user?.role === UserRoles.Coach;

  const showBreadcrumbOnMobile = !isCoach && parent && parent.key !== 'home' && parent.key !== 'teamList';
  const shouldHideBreadcrumbsForCoach = () => {
    const baseWorkspaceRoutes = ['workspaceDetails', 'workspaceUserList', 'workspaceTeamList', 'boundedCourseList'];
    if (isCoach && baseWorkspaceRoutes.includes(routeKey)) return true;
    return false;
  };

  if (!user?.canSaveRecord) {
    return null;
  }

  const DefaultToolbar = (
    <Toolbar className={classes.container}>
      <DynamicContentContainer>
        {/* This children will be overwritten when custom content is set */}
        <div className={classes.breadcrumbs}>
          {!shouldHideBreadcrumbsForCoach() && parent && parent.key !== 'home' && (
            <Link to={getUrlWithParams(queryParams.get('goBack') || parent!.key, params)} className={classes.back}>
              <ChevronLeft onClick={() => removeBreadcrumb()} />
            </Link>
          )}
          <h4>{formatMessage({ id: title })}</h4>
          {breadcrumbs &&
            breadcrumbs.map((i, key) => (
              <span key={key} className={classes.breadcrumb}>
                {i.title}
              </span>
            ))}
        </div>
      </DynamicContentContainer>

      <div className={classes.menuContainer}>
        <LanguagePicker />
        <NotificationPopOver className={classes.notificationIcon} routeMap={NotificationActionRouteEnumMappings} />

        <Avatar className={classes.avatar} src={avatar ?? undefined} alt="avatar" />
      </div>
    </Toolbar>
  );

  const MobileToolbar = (
    <Toolbar className={classes.container}>
      <div className={classes.mobileToolbar}>
        <div className={classes.mobileToolbarTop}>
          <div className={classes.mobileContent}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <div className={classes.mobileMenuContainer}>
            <NotificationPopOver
              className={classes.mobileNotifictionIcon}
              routeMap={NotificationActionRouteEnumMappings}
            />
            {!isMenuOpen && <MenuIcon className={classes.mobileNotifictionIcon} onClick={onMenuClick} />}
            {isMenuOpen && <CloseIcon className={classes.mobileNotifictionIcon} onClick={onMenuClick} />}
          </div>
        </div>
        {!isCustomContent && (
          <div
            className={cx(classes.breadcrumbs, {
              [classes.breadcrumbsOnMobile]: showBreadcrumbOnMobile,
            })}
          >
            {showBreadcrumbOnMobile && (
              <Link to={getUrlWithParams(queryParams.get('goBack') || parent!.key, params)} className={classes.back}>
                <ChevronLeft onClick={() => removeBreadcrumb()} />
              </Link>
            )}
            {breadcrumbs &&
              breadcrumbs.map((i, key) => (
                <span key={key} className={classes.breadcrumb}>
                  {i.title}
                </span>
              ))}
          </div>
        )}
      </div>
    </Toolbar>
  );

  return (
    <AppBar className={classes.root} position={position}>
      {isMobile ? MobileToolbar : DefaultToolbar}
    </AppBar>
  );
};

export default React.memo(DynamicHeader);
