import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.optional]: '(Optional)',
  [IntlKeys.back]: 'Zurück',
  [IntlKeys.dashboard]: 'Dashboard',
  [IntlKeys.courses]: 'Kurse',
  [IntlKeys.users]: 'Nutzer',
  [IntlKeys.coach]: 'Coach',
  [IntlKeys.coaches]: 'Coaches',
  [IntlKeys.generalInfo]: 'Allgemeine Info',
  [IntlKeys.boundCourses]: 'Verbundene Kurse',
  [IntlKeys.favourites]: 'Favoriten',
  [IntlKeys.teams]: 'Teams',
  [IntlKeys.members]: 'Mitglieder',
  [IntlKeys.content]: 'Content',
  [IntlKeys.files]: 'Dateien',
  [IntlKeys.lists]: 'Listen',
  [IntlKeys.folders]: 'Ordner',
  [IntlKeys.folder]: 'Ordner',
  [IntlKeys.community]: 'Community',
  [IntlKeys.workspace]: 'Workspace',
  [IntlKeys.workspaces]: 'Workspaces',
  [IntlKeys.company]: 'Firma',
  [IntlKeys.companies]: 'Firmen',
  [IntlKeys.coachCompanies]: 'Coach Firmen',
  [IntlKeys.settings]: 'Einstellungen',
  [IntlKeys.help]: 'Hilfe',
  [IntlKeys.training]: 'Training',
  [IntlKeys.email]: 'Email',
  [IntlKeys.password]: 'Passwort',
  [IntlKeys.passwordPlaceholder]: 'Geben Sie Ihr Passwort ein',
  [IntlKeys.retypePassword]: 'Geben Sie Ihr Passwort erneut ein',
  [IntlKeys.newPassword]: 'Neues Passwort',
  [IntlKeys.enterPassword]: 'Passwort eingeben',
  [IntlKeys.retypeNewPassword]: 'Neues Passwort widerholen',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePlaceholder]: 'Namen eingeben',
  [IntlKeys.surname]: 'Nachname',
  [IntlKeys.surnamePlaceholder]: 'Geben Sie Ihren Nachnamen ein',
  [IntlKeys.jobTitle]: 'Berufsbezeichnung',
  [IntlKeys.jobTitlePlaceholder]: 'Gebe Sie Ihre Berufsbezeichnung ein',
  [IntlKeys.serverNotAvailableError]: 'Der Server ist nicht verfügbar',
  [IntlKeys.reviewsRequested]: 'Beurteilung angefragt',
  [IntlKeys.reviewsSearch]: 'Suche nach einer Beurteilung',
  [IntlKeys.update]: 'Update',
  [IntlKeys.updates]: 'Updates',
  [IntlKeys.records]: 'Aufnahmen',
  [IntlKeys.lastRecords]: 'Letzte Aufnahmen',
  [IntlKeys.seeAll]: 'Alles anzeigen',
  [IntlKeys.pitchesAndTrainings]: 'Trainings',
  [IntlKeys.cancel]: 'Abbrechen',
  [IntlKeys.add]: 'Hinzufügen',
  [IntlKeys.confirm]: 'Bestätigen',
  [IntlKeys.confirmQuestion]: 'Sind Sie sicher',
  [IntlKeys.ok]: 'OK',
  [IntlKeys.upload]: 'Hochladen',
  [IntlKeys.library]: 'Bibliothek',
  [IntlKeys.dragAndDrop]: 'Drag & Drop oder zum hochladen klicken',
  [IntlKeys.create]: 'Erstellen',
  [IntlKeys.sortBy]: 'Sortieren nach',
  [IntlKeys.change]: 'Ändern',
  [IntlKeys.remove]: 'Entfernen',
  [IntlKeys.removeConfirm]: 'Ja, entfernen',
  [IntlKeys.requiredItem]: 'Dies ist erforderlich',
  [IntlKeys.save]: 'Speichern',
  [IntlKeys.errorSomethingWentWrong]: 'Etwas ist schief gelaufen...',
  [IntlKeys.title]: 'Titel',
  [IntlKeys.enter]: 'Eingeben',
  [IntlKeys.edit]: 'Bearbeiten',
  [IntlKeys.apply]: 'Anwenden',
  [IntlKeys.filter]: 'Filter',
  [IntlKeys.filters]: 'Filter',
  [IntlKeys.status]: 'Status',
  [IntlKeys.share]: 'Teilen',
  [IntlKeys.delete]: 'Löschen',
  [IntlKeys.timer]: 'Timer',
  [IntlKeys.text]: 'Text',
  [IntlKeys.choices]: 'Auswahlmöglichkeiten',
  [IntlKeys.quiz]: 'Quiz',
  [IntlKeys.enterText]: 'Text eingeben',
  [IntlKeys.addText]: 'Text',
  [IntlKeys.or]: 'ODER',
  [IntlKeys.other]: 'Andere',
  [IntlKeys.selectFromList]: 'Aus Liste auswählen',
  [IntlKeys.duplicate]: 'Duplizieren',
  [IntlKeys.of]: 'von',
  [IntlKeys.part]: 'Teil',
  [IntlKeys.dontWorry]: 'Wichtig!',
  [IntlKeys.submit]: 'Einreichen',
  [IntlKeys.retake]: 'wiederholen',
  [IntlKeys.loading]: 'Laden...',
  [IntlKeys.from]: 'Von',
  [IntlKeys.all]: 'Alle',
  [IntlKeys.changesSaved]: 'Änderungen gespeichert',
  [IntlKeys.saveChanges]: 'Änderungen speichern',
  [IntlKeys.enterValidString]: 'Bitte geben Sie eine gültige Zeichenfolge ein',
  [IntlKeys.teamName]: 'Team Name',
  [IntlKeys.emoji]: 'Emoji',
  [IntlKeys.addTeam]: 'Team hinzufügen',
  [IntlKeys.copyLink]: 'Link kopieren',
  [IntlKeys.invalidEmail]: 'ist keine gültige E-Mail Adresse',
  [IntlKeys.linkCopied]: 'Link kopiert',
  [IntlKeys.userName]: 'Nutzername',
  [IntlKeys.role]: 'Rolle',
  [IntlKeys.chooseRole]: 'Wählen Sie eine Rolle',
  [IntlKeys.chooseLanguage]: 'Wählen Sie eine Sprache',
  [IntlKeys.passwordsMustMatch]: 'Passwörter müssen übereinstimmen',
  [IntlKeys.eightCharacterAtLeast]: 'Mindestens 8 Zeichen werden benötigt',
  [IntlKeys.incorrectEmail]: 'Falsche E-Mail',
  [IntlKeys.seeMore]: 'mehr',
  [IntlKeys.employee]: 'Mitarbeiter',
  [IntlKeys.overview]: 'Übersicht',
  [IntlKeys.record]: 'Aufnahme',
  [IntlKeys.startRecording]: 'Starten Sie die Aufnahme',
  [IntlKeys.exportAsSCORMPackage]: 'Als SCORM paket exportieren',
  [IntlKeys.exportAsIframeLink]: 'Als Iframe-Link exportieren',
  [IntlKeys.details]: 'Details',
  [IntlKeys.published]: 'Veröffentlicht',
  [IntlKeys.draft]: 'Entwurf',
  [IntlKeys.disabled]: 'Ausgeschaltet',
  [IntlKeys.completed]: 'Completed',
  [IntlKeys.admin]: 'Admin',
  [IntlKeys.manager]: 'Manager',
  [IntlKeys.scormEmployee]: 'Mitarbeiter (SCORM)',
  [IntlKeys.leavePageProomptMessage]:
    'Sie haben nicht gespeicherte Änderungen! Möchten Sie die Seite wirklich verlassen?',
  [IntlKeys.leavePageQuestion]: 'Seite verlassen?',
  [IntlKeys.uploadInProgress]: 'Uploading {count, plural, =0 {items} one {# item} other {# items}}',
  [IntlKeys.uploadProgressPromptMessage]:
    'Ihre Dateien werden hochgelanden. Sollten Sie diese Seite verlassen können eventuell nicht alle Dateien gespeichert werden',
  [IntlKeys.stopRecording]: 'Stoppen Sie die Aufnahme',
  [IntlKeys.archive]: 'archivieren',
  [IntlKeys.unArchive]: 'wiederherstelln',
  [IntlKeys.yes]: 'Ja',
  [IntlKeys.no]: 'Nein',
  [IntlKeys.language]: 'Sprache',
  [IntlKeys.viewDetails]: 'Zeige Details',
  [IntlKeys.task]: 'Aufgabe',
  [IntlKeys.taskAudio]: 'Audio Aufgabe',
  [IntlKeys.taskInfo]: 'Aufgaben Info',
  [IntlKeys.taskText]: 'Text Aufgabe',
  [IntlKeys.taskVideo]: 'Video Aufgabe',
  [IntlKeys.accept]: 'Akzeptieren',
  [IntlKeys.reject]: 'Ablehnen',
  [IntlKeys.phone]: 'Telefon',
  [IntlKeys.enterPhone]: 'Geben Sie eine Telefonnummer ein',
  [IntlKeys.enterSurname]: 'Geben Sie einen Nachnamen ein',
  [IntlKeys.summary]: 'Zusammenfassung',
  [IntlKeys.timeline]: 'Timeline',
  [IntlKeys.showDesktop]: 'Zeige Desktop Ansicht',
  [IntlKeys.showMobile]: 'Zeige Mobile Ansicht',
  [IntlKeys.logo]: 'Logo',
  [IntlKeys.type]: 'Type',
  [IntlKeys.search]: 'Suche',
  [IntlKeys.copyIframeLinkTitle]: 'Iframe-Daten kopieren',
  [IntlKeys.copiedToClipboard]: 'In die Zwischenablage kopiert',
  [IntlKeys.failedToCopy]: 'Kopieren fehlgeschlagen',
  [IntlKeys.copy]: 'Kopieren',
  [IntlKeys.copyIframeNote]:
    'Hinweis: Vergessen Sie nicht, Ihrem Iframe-Element Berechtigungen für Mikrofon und Kamera zu erteilen',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Willkommen zurück bei HeyPractice!',
  [IntlKeys.loginSubtitle]: 'Bitte loggen Sie sich in Ihren Account ein um fortzufahren',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Sie haben noch keinen Account?',
  [IntlKeys.signInError401]: 'Falsche E-Mail oder Passwort',
  [IntlKeys.externalTrainingSignInDefaultErrorMessage]:
    'Training konnte nicht geladen werden. Bitte versuchen Sie es später erneut',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Passwort vergessen',
  [IntlKeys.forgotPasswordQuestion]: 'Passwort vergessen?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Geben Sie die E-Mail Adresse ein welche mit Ihrem Account verknüpft ist',
  [IntlKeys.forgotPasswordSendLink]: 'Link zum Zurücksetzen senden',
  [IntlKeys.forgotPasswordBackToLogin]: 'Zurück zum Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Link der an Ihre E-Mail gesendet wurde',
  [IntlKeys.forgotPasswordError404]: 'Diese E-Mail Adresse existiert nicht',

  //RESET PASSWORD PAGE
  [IntlKeys.resetPassword]: 'Passwort zurücksetzen',
  [IntlKeys.resetPasswordSubtitle]: 'Bitte geben Sie ein neues Passwort ein',
  [IntlKeys.resetPasswordChangePassword]: 'Passwort ändern',
  [IntlKeys.resetPasswordHaveAccount]: 'Haben Sie schon einen Account?',
  [IntlKeys.resetPasswordFulfilled]: 'Passwort erfolgreich geändert',
  [IntlKeys.resetPasswordError404]: 'Dieser Link ist ungültig',
  [IntlKeys.resetPasswordError422]: 'Server kann die Anfrage nicht verarbeiten',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Anmelden',
  [IntlKeys.signUpTitle]: 'HeyPractice beitreten',
  [IntlKeys.signUpSubTitle]: 'Erstellen Sie einen Account und starten Sie ihr Training!',
  [IntlKeys.signUpHaveAccount]: 'Sie haben einen Account?',
  [IntlKeys.signUpFulfilled]: 'Benutzer erfolgreich erstellt',
  [IntlKeys.signUpError404]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError422]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError500]: 'Diese E-Mail Adresse existiert bereits',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'Dieses Feld wird benötigt',
  [IntlKeys.formTimeExceedsTimer]: 'Zeit überschreitet Ihren gewählten Timer',

  // Courses
  [IntlKeys.noCoursesAvailable]: 'Sieht so aus, als hätten Sie noch keine Kurse',
  [IntlKeys.addNewCourse]: 'Neuen Kurs hinzufügen',
  [IntlKeys.createCourse]: 'Kurs erstellen',
  [IntlKeys.createCourseSubmit]: 'Neuen Kurs erstellen',
  [IntlKeys.createCourseFormLabelName]: 'Kurs Name',
  [IntlKeys.createCourseFormLabelDescription]: 'Beschreibung',
  [IntlKeys.createCourseFormLabelTags]: 'Tags',
  [IntlKeys.createCourseFormLabelTeams]: 'Teams',
  [IntlKeys.createCourseFormLabelCover]: 'Titelbild',
  [IntlKeys.courseDetails]: 'Zum Kurs',
  [IntlKeys.coursesSearch]: 'Kurs suchen',
  [IntlKeys.coursesDetachTeamTooltip]: 'Team trennen',
  [IntlKeys.coursesAttchTeamTooltip]: 'Team anhängen',
  [IntlKeys.coursesDetachMemberTooltip]: 'Mitglied entfernen',
  [IntlKeys.coursesAttachMemberTooltip]: 'Mitglied anhängen',
  [IntlKeys.coursesDetachUnableMemberTooltip]:
    'Mitglieder die zu angehangenen Teams gehören können nicht entfernt werden',
  [IntlKeys.coursesRecordsView]: 'Aufnahmen anzeigen',
  [IntlKeys.coursesAddCoursesToTeam]: 'Kurse zum Team hinzufügen',
  [IntlKeys.coursesClearSelection]: 'Auswahl löschen',
  [IntlKeys.coursesCourseNameAToZ]: 'Kursnamen A-Z',
  [IntlKeys.coursesCourseNameZToA]: 'Kursnamen Z-A',
  [IntlKeys.coursesAuthorNameAToZ]: 'Autorenname A-Z',
  [IntlKeys.coursesAuthorNameZToA]: 'Autorenname Z-A',
  [IntlKeys.coursesLastUpdates]: 'Letzte Updates',
  [IntlKeys.coursesOldUpdates]: 'Alte Updates',
  [IntlKeys.courseClearFilter]: 'Filter entfernen',
  [IntlKeys.coursesAddCourse]: 'Kurs hinzufügen',
  [IntlKeys.connectedCourses]: '{count, plural, =0 {kein Kurs} one {# Kurs} other {# Kurse}} verknüpft',
  [IntlKeys.coursesAttachToWorkspaces]: 'Zu Workspaces hinzufügen',

  // Course
  [IntlKeys.courseAttachDetachMember]: 'Mitglied hinzufügen / entfernen',
  [IntlKeys.courseAddTeam]: 'Team hinzufügen',
  [IntlKeys.courseAddWorkspace]: 'Manage Workspacezugriff',
  [IntlKeys.courseAttachWorkspace]: 'Workspace hinzufügen',
  [IntlKeys.courseDetachWorkspace]: 'Workspace entfernen',
  [IntlKeys.courseOverview]: 'Übersicht',
  [IntlKeys.courseHistory]: 'Verlauf',
  [IntlKeys.courseViewRecords]: 'Aufnahmen anzeigen',
  [IntlKeys.courseUpdateCourse]: 'Kurs updaten',
  [IntlKeys.coursePublish]: 'veröffentlichen',
  [IntlKeys.coursePublishFailed]: 'veröffentlichen des Kurses fehlgeschlagen',
  [IntlKeys.courseExportToSCORM]: 'Nach SCORM exportieren',
  [IntlKeys.courseNoTeamAvailable]: 'Sieht so aus, als hätten Sie noch keine Teams',
  [IntlKeys.courseCreatingTeamSuggestion]: 'Sie können einfach ein neues Team erstellen und mit dem Training beginnen',
  [IntlKeys.coursePublishCourseQuestionTitle]: 'Kurs veröffentlichen?',
  [IntlKeys.coursePublishCourseQuestionSubText]:
    'Sind Sie sicher, dass Sie den Kurs für Ihr Training veröffentlichen willst?',
  [IntlKeys.coursePublishCourseQuestionConfirmBtnText]: 'Ja, veröffentlichen',
  [IntlKeys.courseAddTags]: 'Tags hinzufügen',
  [IntlKeys.courseAddTeams]: 'Teams hinzufügen',
  [IntlKeys.courseEnterTag]: 'Tag eingeben',
  [IntlKeys.courseAuthor]: 'Autor',
  [IntlKeys.courseSendInvite]: 'Einladung senden',
  [IntlKeys.courseEnterEmail]: 'Geben Sie E-Mail Adressen ein, drücken Sie Eingabe um diese hinzuzufügen',
  [IntlKeys.courseRemoveCourse]: 'Kurs entfernen',
  [IntlKeys.courseRemovingCourseConfirmQuestion]: 'Sind Sie sicher, dass Sie den Kurs entfernen wollen?',
  [IntlKeys.courseRemovingCourseDescription]:
    'Alle mit dem Kurs verbundenen Informationen werden gelöscht, einschließlich der mit dem Kurs verbundenen Benutzeraufzeichnungen und Ergebnisse',
  [IntlKeys.courseShareCourse]: 'Kurs teilen',
  [IntlKeys.courseCompleted]: 'Abgeschlossen',
  [IntlKeys.courseDateCreated]: 'Erstellungsdatum',
  [IntlKeys.courseDetachAttachTriggerAttachFulfilled]: 'Erfolgreich zum Team hinzugefügte Kurse',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeam]: 'Vom Team trennen',
  [IntlKeys.courseDetachAttachTriggerAddToAnotherTeam]: 'Einem anderen Team hinzufügen',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeamConfirmQuestion]:
    'Sind Sie sicher, dass Sie die ausgewählten Kurse vom Team trennen wollen?',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeamConfirmBtnText]: 'Ja, trennen',
  [IntlKeys.courseProgressTitle]: 'Fortschritt',
  [IntlKeys.courseLeadershipBoardTitle]: 'Kurs Bestenliste',
  [IntlKeys.courseCompletedStatus]: 'Kurs beendet',
  [IntlKeys.courseDeletionSuccess]: 'Kurs erfolgreich gelöscht',
  [IntlKeys.courseDeletionFailure]: 'Löschen des Kurses fehlgeschlagen',
  [IntlKeys.courseDuplicateTitle]: 'Kurs duplizieren',
  [IntlKeys.courseDuplicateDesc]: 'Möchten Sie den Kurs wirklich duplizieren?',
  [IntlKeys.courseDuplicateConfirm]: 'Ja, duplizieren',
  [IntlKeys.courseArchiveCourse]: 'Kurs Archiv',
  [IntlKeys.courseArchivingCourseConfirmQuestion]: 'Möchten Sie den Kurs wirklich archivieren?',
  [IntlKeys.archiveConfirm]: 'Ja, archivieren',
  [IntlKeys.courseArchivingSuccess]: 'Kurs erfolgreich archiviert',
  [IntlKeys.courseArchivingFailure]: 'Kursarchivierung fehlgeschlagen',
  [IntlKeys.courseUnArchiveCourse]: 'Kurs wiederherstellen',
  [IntlKeys.courseUnArchivingCourseConfirmQuestion]: 'Möchten Sie den Kurs wirklich wiederherstellen?',
  [IntlKeys.unArchiveConfirm]: 'Ja, wiederherstellen',
  [IntlKeys.courseUnArchivingSuccess]: 'Kurs erfolgreich wiederhergestellt',
  [IntlKeys.courseUnArchivingFailure]: 'Kurswiederhertellung fehlgeschlagen',
  [IntlKeys.courseSearchForCompany]: 'Suchen Sie nach einer Firma',
  [IntlKeys.courseScormLeavingQuestion]: 'Sie haben den {type} beendet, möchten Sie die Seite verlassen?',
  [IntlKeys.courseScormLeavingSubmitText]: 'Verlassen',
  [IntlKeys.courseScormLeavingSubtitle]:
    'Sie habel alle Schritte erfolgreich abgeschlossen, möchten Sie einige Aufgaben wiederholen oder möchten Sie zurück zum LMS?',
  [IntlKeys.courseEmptyCourseSubText]: 'Der Kurs ist leider nicht verfügbar.',

  // Teams
  [IntlKeys.teamSearch]: 'Nach einem Team suchen',
  [IntlKeys.teamsAndMembers]: 'Teams & Mitglieder',
  [IntlKeys.teamEmptyTeam]: 'Sieht so aus, als wäre kein Team in diesen Kurs verfügbar.',
  [IntlKeys.teamAccessLimited]: 'Oops! Kein Mitglied kann auf den Kurs zugreifen.',
  [IntlKeys.teamsProgress]: 'Teamfortschritt',
  [IntlKeys.teamNoLeaderBoardMembers]: 'Keine Statistiken',
  [IntlKeys.teamNoLeaderBoardMembersSubTitle]:
    'Kein Fortschritt verfügbar. Sie werden ihn sehen sobald Nutzer ihre Trainings beginnen',
  [IntlKeys.teamMembersProgressTitle]: 'Fortschritt',
  [IntlKeys.teamMembersLeadershipBoardTitle]: 'Bestenliste',
  [IntlKeys.teamManageMembers]: 'Manage Mitglieder',

  // Dashboard
  [IntlKeys.dashboardActiveUsers]: 'Aktivste Nutzer',
  [IntlKeys.dashboardActiveWorkspaces]: 'Aktivste Workspaces',
  [IntlKeys.dashboardCourseCompletion]: 'Kurs Fortschritt',
  [IntlKeys.dashboardCourseCompletionSubline]: 'Wähle einen Workspace und einen Kurs',
  [IntlKeys.dashboardCoursesPopular]: 'Beliebteste Kurse',
  [IntlKeys.dashboardLast30Days]: 'letzte 30 Tage',
  [IntlKeys.dashboardMostActiveUser]: 'Aktivster Nutzer',
  [IntlKeys.dashboardMostActiveWorkspace]: 'Aktivster Workspace',
  [IntlKeys.dashboardNewRecords]: 'Neue Aufnahmen',
  [IntlKeys.dashboardNoUsers]: 'Keine Nutzer verfügbar',
  [IntlKeys.dashboardNoWorkspaces]: 'Keine Workspaces verfügbar',
  [IntlKeys.dashboardSessionsCount]: '{count, plural, =0 {keine Sitzungen} one {# Sitzung} other {# Sitzungen}}',
  [IntlKeys.dashboardUsersCount]: '{count, plural, =0 {keine Nutzer} one {# Nutzer} other {# Nutzer}}',
  [IntlKeys.dashboardUserLeaderboard]: 'Nutzer Bestenliste',
  [IntlKeys.dashboardWorkspaceLeaderboard]: 'Workspace Bestenliste',

  // Admin dashboard
  [IntlKeys.adminDashboardMyDrafts]: 'Meine Entwürfe',

  // Manager Dashboard
  [IntlKeys.managerDashboardTeamsActivity]: 'Meine aktiven Kurse',
  [IntlKeys.managerDashboardMembersActivity]: 'Nutzeraktivität',
  [IntlKeys.managerDashboardNoLeaderboardAvailable]:
    'Keine Bestenliste verfügbar. Sie wird erscheinen sobald Nutzer ihre Training starten',

  // Company
  [IntlKeys.activeStatus]: 'Aktuell',
  [IntlKeys.leadStatus]: 'Kunde',
  [IntlKeys.archivedStatus]: 'Archiviert',
  [IntlKeys.addCompany]: 'Unternehmen hinzufügen',
  [IntlKeys.companyName]: 'Unternehmensname',
  [IntlKeys.companyType]: 'Branche',
  [IntlKeys.noOfEmployees]: 'Anzahl der Mitarbeiter',
  [IntlKeys.tabGeneralInfo]: 'Allgemeine Infos',
  [IntlKeys.tabUsers]: 'Benutzer',
  [IntlKeys.companyAcceptQuestionTitle]: '{companyName} akzeptieren',
  [IntlKeys.companyAcceptQuestionSupText]: 'Sind Sie sicher, dass Sie das Unternehmen akzeptieren möchten?',
  [IntlKeys.companyAcceptConfirmBtnText]: 'Ja, akzeptieren',
  [IntlKeys.companyRejectQuestionTitle]: '{companyName} ablehnen',
  [IntlKeys.companyRejectQuestionSupText]:
    'Sind Sie sicher, dass Sie das Unternehmen ablehnen möchten? Das Unternehmen wird gelöscht.',
  [IntlKeys.companyRejectConfirmationFieldLabel]: 'Gebe den Unternehmensnamen als Bestätigung ein',
  [IntlKeys.companyRejectConfirmBtnText]: 'Ja, Ablehnen',
  [IntlKeys.companySuperAdmin]: 'Super admin',
  [IntlKeys.companyEnterEmail]: 'E-Mail eingeben',
  [IntlKeys.companyRejectCompanyNameMustMatch]: 'Name des Unternehmens ist inkorrekt!',
  [IntlKeys.companyRejectCompanyFullfilled]: 'Unternehmen wurde erfolgreich abgelehnt',
  [IntlKeys.companyAcceptCompanyFullfilled]: 'Unternehmen wurde erfolgreich akzeptiert',
  [IntlKeys.companyRemove]: 'Unternehmen löschen',
  [IntlKeys.companyRemoveSubText]: 'Sind Sie sicher das Sie das Unternehmen löschen möchten?',
  [IntlKeys.companyRemoveDescription]: 'Alle Daten, die mit dem Unternehmen verknüpft sind, werden ebenfalls gelöscht.',
  [IntlKeys.companyArchive]: 'Unternehmen archivieren',
  [IntlKeys.companyArchiveSubText]: 'Sind Sie sicher das Sie das Unternehmen archivieren möchtest?',
  [IntlKeys.companyArchiveDescription]:
    'Archivierte Unternehmen haben keinen Zugriff auf HeyPractice. Alle Daten werden weiterhin verfügbar sein.',
  [IntlKeys.companyUnArchive]: 'Unternehmen wiederherstellen',
  [IntlKeys.companyUnArchiveSubText]: 'Sind Sie sicher das Sie das Unternehmen wiederherstellen möchten?',

  // Employee Dashboard
  [IntlKeys.employeeDashboardActiveCourses]: 'Aktive Kurse',
  [IntlKeys.employeeDashboardScoreDesc]: 'Score ↓',
  [IntlKeys.employeeDashboardScoreAsc]: 'Score ↑',
  [IntlKeys.employeeDashboardCompletedPercentDesc]: 'Fortschritt ↓',
  [IntlKeys.employeeDashboardCompletedPercentAsc]: 'Fortschritt ↑',
  [IntlKeys.employeeDashboardLeadersActivities]: 'Beste Aktivität',

  // Feedback
  [IntlKeys.feedbacks]: 'Rückmeldungen/Feedbacks',
  [IntlKeys.feedbackForTraining]: 'Für Training:',
  [IntlKeys.feedbackInTheCourse]: 'Im Kurs:',
  [IntlKeys.feedbackNoFeedbackProvided]: '-- Kein Feedback abgegeben --',
  [IntlKeys.feedbackEnterYourFeedback]: 'Tippen Sie Ihr Feedback ein',

  // Content
  [IntlKeys.contentPresentation]: 'Präsentation',
  [IntlKeys.contentCreatedBy]: 'Erstellt von',
  [IntlKeys.contentTypeOfContentFolders]: 'Ordner',
  [IntlKeys.contentTypeOfContentFiles]: 'Dateien',
  [IntlKeys.contentTypeOfContentLists]: 'Listen',
  [IntlKeys.contentUploadFile]: 'Upload daten',
  [IntlKeys.contentAddFolder]: 'Ordner hinzufügen',
  [IntlKeys.contentCreateList]: 'Erstelle Liste',
  [IntlKeys.contentRecentFiles]: 'Aktuelle Dateien',
  [IntlKeys.contentAddContent]: 'Inhalt hinzufügen',
  [IntlKeys.contentNothingToShow]: 'Nichts zu zeigen',
  [IntlKeys.contentNoFileAvailable]: 'Sieht so aus, als hätten Sie noch keine Dateien',
  [IntlKeys.contentFileEmpty]: 'Der Ordner ist leer',
  [IntlKeys.contentListSubtitle]:
    'Sie können einfach einen neuen Ordner erstellen, Dateien hochladen oder eine Reaktionsliste hinzufügen',
  [IntlKeys.contentCreateFile]: 'Datei erstellen',
  [IntlKeys.contentNewFolder]: 'Neuer Ordner',
  [IntlKeys.contentSearchContent]: 'Inhalt suchen',
  [IntlKeys.contentSymbolQuantityLimited]: 'Es sollten mindestens 3 Zeichen verwendet werden',
  [IntlKeys.contentDownload]: 'Download',
  [IntlKeys.contentDetails]: 'Details',
  [IntlKeys.contentPreview]: 'Vorschau',
  [IntlKeys.contentAddAudio]: 'Audio',
  [IntlKeys.contentAddDocument]: 'Dokument',
  [IntlKeys.contentReactionName]: 'Name der Einblendung',
  [IntlKeys.contentSelectEmoji]: 'Emoji auswählen',
  [IntlKeys.contentSaveReaction]: 'Reaktion speichern',
  [IntlKeys.contentEnterName]: 'Name eingeben',
  [IntlKeys.contentAddFiles]: 'Dateien hinzufügen',
  [IntlKeys.contentSearchFiles]: 'Dateien suchen',
  [IntlKeys.contentSearchFolder]: 'Ordner suchen',
  [IntlKeys.contentCreateFolder]: 'Ordner erstellen',
  [IntlKeys.contentSearch]: 'Suchen Sie einen Kurs',
  [IntlKeys.noContentAvailable]: 'Anscheinend haben Sie keinen Dateien',

  // Content List
  [IntlKeys.contentListListName]: 'Listenname',
  [IntlKeys.contentListEnterListName]: 'Gebe Listenname ein',
  [IntlKeys.contentListEvaluationCriterias]: 'Bewertungskriterien',
  [IntlKeys.contentListEvaluationCriteria]: 'Bewertungskriterium',
  [IntlKeys.contentListAddCriteria]: 'Kriterium hinzufügen',
  [IntlKeys.contentListSaveCriteria]: 'Kriterium speichern',
  [IntlKeys.contentListEnterDescription]: 'Beschreibung eingeben',
  [IntlKeys.contentListReactions]: 'Reaktionen',
  [IntlKeys.contentListDoWords]: 'Do-Word',
  [IntlKeys.contentListDoWordsExplained]:
    '"Do-Words" sind Worte, die bei der  Aufgabenbearbeitung genutzt werden sollen.',
  [IntlKeys.contentListDontWords]: "Don't-Words",
  [IntlKeys.contentListDontWordsExplained]:
    '"Don\'t-Words" sind Worte, die in der Aufgabenbearbeitung vermieden werden sollen.',
  [IntlKeys.contentListEnterDoWords]: 'Geben Sie ein Do-Wort ein',
  [IntlKeys.contentListEnterDontWords]: 'Geben Sie ein Dont-Wort ein',
  [IntlKeys.contentListEnterFileName]: 'Geben Sie einen Dateinamen ein',
  [IntlKeys.contentListEnterFolderName]: 'Geben Sie einen Ordnernamen ein',
  [IntlKeys.contentListAddContentList]: 'Fügen Sie eine Liste hinzu',
  [IntlKeys.contentListSearchContentList]: 'Durchsuchen Sie die Listen',

  //DropzoneCard
  [IntlKeys.dropzoneCardLibrary]: 'Bibliothek',
  [IntlKeys.dropzoneCardLink]: 'Link',
  [IntlKeys.dropzoneCardEnterLink]: 'Link eingeben',
  [IntlKeys.dropzoneCardAudio]: 'Audio',
  [IntlKeys.dropzoneCardImage]: 'Bild',
  [IntlKeys.dropzoneCardVideo]: 'Video',

  // LogOut
  [IntlKeys.logOut]: 'ausloggen',
  [IntlKeys.logOutConfirmQuestion]: 'Sind Sie sicher, dass Sie sich ausloggen möchten?',
  [IntlKeys.logOutConfirmBtnText]: 'Ja, ausloggen',

  // Alert messages
  [IntlKeys.alertFileCreatingFulfilled]: 'Datei erfolgreich erstellt',
  [IntlKeys.alertFileDeletingFulfilled]: 'Datei erfolgreich gelöscht',
  [IntlKeys.alertFileUpdatingFulfilled]: 'Dateiupdate erfolgreich',
  [IntlKeys.alertFileUpdatingFailed]: 'Dieser Dateiname wird verwendet. Bitte versuchen Sie es mit einem anderen Namen',
  [IntlKeys.alertFileCreatingFailed]: 'Dieser Dateiname wird verwendet. Bitte versuchen Sie es mit einem anderen Namen',
  [IntlKeys.alertFolderCreatingFulfilled]: 'Ordner erfolgreich erstellt',
  [IntlKeys.alertFolderDeletingFulfilled]: 'Ordner erfolgreich gelöscht',
  [IntlKeys.alertFolderUpdatingFulfilled]: 'Ordnerupdate erfolgreich',
  [IntlKeys.alertListCreatingFulfilled]: 'Liste erfolgreich erstellt',
  [IntlKeys.alertListDeletingFulfilled]: 'Liste erfolgreich gelöscht',
  [IntlKeys.alertListUpdatingFulfilled]: 'Listenupdate erfolgreich',
  [IntlKeys.alertNotSupportFileFormat]: 'Dateiformat nicht unterstützt',
  [IntlKeys.alertCoursesAttachingFulfilled]: 'Kurse erfolgreich dem Team angehangen',
  [IntlKeys.alertSlideUpdatingFulfilled]: 'Folien-Update war erfolgreich',
  [IntlKeys.alertSlidePublishingFulfilled]: 'Training wurde erfolgreich veröffentlicht',
  [IntlKeys.alertSlidePublishingFailed]: 'Veröffentlichung fehlgeschlagen',
  [IntlKeys.alertReviewSubmittingFulfilled]: 'Review erfolgreich eingereicht',
  [IntlKeys.alertSomethingWentWrongTryAgain]: 'Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut',
  [IntlKeys.alertMembersEditingFulfilled]: 'Die Mitgliederliste wurde erfolgreich bearbeitet.',
  [IntlKeys.alertMembersUpdatingFailed]: 'Update der Mitgliederliste ist fehlgeschlagen',
  [IntlKeys.alertSavingChangesFulfilled]: 'Änderungen erfolgreich gespeichert',
  [IntlKeys.alertCloningCourseFulfilled]: 'Kurs erfolgreich geklont',
  [IntlKeys.alertUpdatingPitchFulfilled]: 'Trainingsupdate war erfolgreich',
  [IntlKeys.alertCreatingPitchFulfilled]: 'Training erfolgreich erstellt',
  [IntlKeys.alertCopyingPitchToCourseFulfilled]: 'Training erfolgreich in den Kurs kopiert',
  [IntlKeys.alertDeletingPitchFulfilled]: 'Training erfolgreich gelöscht',
  [IntlKeys.alertDeletingCourseFulfilled]: 'Kurs erfolgreich gelöscht',
  [IntlKeys.alertDeletingCourseFailed]: 'Kurs konnte nich gelöscht werden',
  [IntlKeys.alertCloningCourseFailed]: 'Kursduplizierung fehlgeschlagen',
  [IntlKeys.alertUpdatingPitchFailed]: 'Trainingsupdate fehlgeschlagen',
  [IntlKeys.alertCreatingPitchFailed]: 'Trainingserstellung fehlgeschlagen',
  [IntlKeys.alertCopyingPitchToCourseFailed]: 'Kopiervorgang vom Training in den Kurs fehlgeschlagen',
  [IntlKeys.alertDeletingPitchFailed]: 'Pitschlöschung fehlgeschlagen',
  [IntlKeys.alertDeletingCommentFailed]:
    'Entschuldigung! Konnte den Kommentar nicht löschen, bitte versuchen Sie es erneut',
  [IntlKeys.alertEditingCommentFailed]:
    'Entschuldigung! Konnte den Kommentar nich updaten, bitte versuchen Sie es erneut',
  [IntlKeys.alertUsersCreatingFailed]: 'Entschuldigung! Konnte den Nutzer nicht anlegen, bitte versuchen Sie es erneut',
  [IntlKeys.alertDetachingMemberFulfilled]: 'Mitglied erfolgreich entfernt',
  [IntlKeys.alertDetachingMemberFailed]: 'Entschuldigung! Konnte Mitglied nicht entfernen.',
  [IntlKeys.alertFavoritingCourseFailed]: 'Entschuldigung! Konnte Kurs nich zu Ihren Favoriten hinzufügen.',
  [IntlKeys.alertFavoritingPitchFailed]: 'Entschuldigung! Konnte Training nicht zu Ihren Favoriten hinzufügen.',
  [IntlKeys.alertCreatingTeamTrainingFailed]:
    'Entschuldigung! Konnte kein Team-Training erstellen, bitte versuchen Sie es erneut',
  [IntlKeys.alertCreatingTeamTrainingFulfilled]: 'Team-Training erstellt',
  [IntlKeys.alertCoursesAttachingToWorkspaceFulfilled]: 'Kurse erfolgreich an Workspace angehängt',
  [IntlKeys.alertSlideUploadingFileFailed]: 'Entschuldigung! Hochladen der Datei ist fehlgeschlagen',
  [IntlKeys.alertSlideUploadingFileFulfilled]: 'Datei erfolgreich hochgeladen',
  [IntlKeys.alertDeletingUserFulfilled]: 'Nutzer erfolgreich gelöscht',
  [IntlKeys.alertDeletingUserFailed]: 'Entschuldigung! Konnte den Nutzer nicht löschen.',
  [IntlKeys.alertArchivingUserFulfilled]: 'Nutzer erfolgreich archiviert',
  [IntlKeys.alertArchivingUserFailed]: 'Entschuldigung! Konnte Nutzer nicht archivieren.',
  [IntlKeys.alertArchivingUserFailedUserWorkspaceAdmin]:
    'Ups! Sie können den Admin Nutzer nicht archivieren. Ändern Sie die Nutzer Rolle zu "Mitarbeiter" und versuchen Sie es erneut! ',
  [IntlKeys.alertUnArchivingUserFulfilled]: 'Nutzer erfolgreich wiederhergestellt',
  [IntlKeys.alertUnArchivingUserFailed]: 'Entschuldigung! Konnte Nutzer nicht wiederherstellen.',
  [IntlKeys.alertSlideUploadingPresentationFailed]: 'Entschuldigung! Konnte die Presentation nicht importieren.',
  [IntlKeys.alertSlideUploadingPresentationFulfilled]: 'Presentation erfolgreich in Folie kopiert',
  [IntlKeys.alertEditingUserFulfilled]: 'Nutzer erfolgreich bearbeitet',
  [IntlKeys.alertEditingUserFailed]: 'Entschuldigung! Konnte den Nutzer nicht bearbeiten.',
  [IntlKeys.alertSlideUpdatingFailed]:
    'Entschuldigung! Konnte die Folien nicht speichern. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut',
  [IntlKeys.alertMicrophoneIsDisabled]: 'Bitte schalten Sie das Mikrofon ein um mit dem Training zu starten',
  [IntlKeys.alertRevokeInvitationsFulfilled]: 'Einladungen erfolgreich widerrufen',
  [IntlKeys.alertRevokeInvitationsFailed]: 'Entschuldigung! Konnte Einladungen nicht widerrufen.',

  // Pitches
  [IntlKeys.pitches]: 'Trainings',
  [IntlKeys.pitchesNotAvailable]: 'Dieses Training ist nicht verfügbar!',
  [IntlKeys.pitchesAddWord]: 'Wort hinzufügen.',
  [IntlKeys.pitchesSelectFromLists]: 'Aus Liste auswählen',
  [IntlKeys.pitchesNoListAvailable]: 'Keine Listen verfügbar',
  [IntlKeys.pitchesAiFeedbackTab]:
    'AI Feedback erlaubt die Einrichtung eines direkten, automatischen Feedbacks direkt nach der Aufgabe. Es beinhält: Gesprochene Wörter pro Minute, Lautstärke und Stimmklarheit sowie die Anzahl der benutzen dont Worte.',
  [IntlKeys.pitchesEvaluationCriteriaLabel]: 'Bewertungskriterien',
  [IntlKeys.pitchesEvaluationCriteriaOverview]: 'Übersicht der Bewertungskriterien',
  [IntlKeys.pitchesEvaluationCriteriaAddNew]: 'Fügen Sie neue Bewertungskriterien hinzu',
  [IntlKeys.pitchesEvaluationCriteriaUpdate]: 'Bewertungskriterien updaten',
  [IntlKeys.PitchesAddImage]: 'Bild',
  [IntlKeys.PitchesAddVideo]: 'Video',
  [IntlKeys.pitchesMaterialsTitle]: 'Materialien',
  [IntlKeys.pitchesMaterialsAddMediaMessage]:
    'Die hier hochgeladenen Materialien können sich die Lernenden noch vor Lösung der Aufgabe ansehen.',
  [IntlKeys.pitchesMaterialsAddDocument]: 'Dokument',
  [IntlKeys.pitchesObjectives]: 'Start-Einblendung',
  [IntlKeys.pitchesObjectivesWillAppear]: 'erscheint sobald die Aufgabe gestartet wird',
  [IntlKeys.pitchesPlaybook]: 'Agenda',
  [IntlKeys.pitchesPlaybookCreatePlaybookFollowing]: 'erstelle eine Agenda und füge weitere Einblendungen hinzu',
  [IntlKeys.pitchesPlaybookAddPlaybookPart]: 'Agendapunkt hinzufügen',
  [IntlKeys.pitchesPlaybookEpisodeDuration]: 'Episodendauer',
  [IntlKeys.pitchesPlaybookAddReactions]: 'Einblendung hinzufügen',
  [IntlKeys.pitchesPlaybookTime]: 'Zeit der Episode',
  [IntlKeys.pitchesPlaybookTimetoAppear]: 'Zeitpunkt der Einblendung in der Episode',
  [IntlKeys.pitchesPlaybookRandomReactionSelect]: 'Zufällige Einblendung auswählen',
  [IntlKeys.pitchesPlaybookRandomReactionSecText]:
    'Bei zufälliger Auswahl, werden die Zeiten der Einblendungen ignoriert',
  [IntlKeys.pitchesReactionsAndObjections]: 'Einblendungen',
  [IntlKeys.pitchesTypeTabRecordVideoToTask]: 'Es wird ein Video über die Webcam aufgenommen',
  [IntlKeys.pitchesTypeTabRecordAudioToTask]: 'Es wird die Tonspur aufgenommen',
  [IntlKeys.pitchesTypeTabRecordTextToTask]: 'Die Aufgabe wird in Textform bearbeitet',
  [IntlKeys.pitchesTypeTabTimeBasedQuiz]: 'Erstellen Sie ein zeitbasiertes Quiz',
  [IntlKeys.pitchesTypeTabDisplayInformationImage]: 'Den Nutzenden wird ein Bild gezeigt',
  [IntlKeys.pitchesTypeTabPlayLearningAudio]: 'Es wird eine Audiodatei abgespielt',
  [IntlKeys.pitchesTypeTabTask]: 'Aufgabe',
  [IntlKeys.pitchesTypeTabTaskAskingUserToDoTask]:
    'Der Aufgabentyp beschreibt, welches Medium die Nutzenden zur Beantwortung der Aufgabe nutzen.',
  [IntlKeys.pitchesTypeTabKnowledgeSlide]: 'Wissensfolie',
  [IntlKeys.pitchesTypeTabContentSlide]: 'Wissensfolie',
  [IntlKeys.pitchesTypeTabContentSlideSubtitle]:
    'Stellen Sie Wissen oder Informaterial zur Verfügung um auf die nächste Aufgabe vorzubereiten',
  [IntlKeys.pitchesTypeTabProvideKnowledgeSlide]: 'Stellen Sie eine Wissensfolie bereit',
  [IntlKeys.pitchesTypeTabShowEducationalVideoRecordOrExample]: 'Den Nutzenden wird ein Video gezeigt',
  [IntlKeys.pitchesFormTabsType]: 'Typ',
  [IntlKeys.pitchesFormTabsContent]: 'Inhalt',
  [IntlKeys.pitchesFormTabsDynamicContent]: 'Dynamischer Inhalt',
  [IntlKeys.pitchesFormTabsQuizAnswers]: 'Richtige Antworten',
  [IntlKeys.pitchesFormTabsAiFeedback]: 'KI Feedback',
  [IntlKeys.pitchesWordsTabAddNewWord]: 'Neues Wort hinzufügen',
  [IntlKeys.pitchesConditionsTabHeadlineLabel]: 'Aufgabenstellung',
  [IntlKeys.pitchesConditionsTabEnterHeadline]: 'Aufgabenstellung einfügen',
  [IntlKeys.pitchesPublish]: 'Veröffentlichen',
  [IntlKeys.pitchesPublishConfirmQuestion]: 'Training veröffentlichen?',
  [IntlKeys.pitchesPublishSubText]:
    'Nachdem Sie das Training veröffentlicht haben, können Sie es nicht mehr aktualisieren',
  [IntlKeys.pitchesPublishConfirmBtnText]: 'Ja, veröffentlichen',
  [IntlKeys.pitchesAddNewSlides]: 'Neue Folie hinzufügen',
  [IntlKeys.pitchesAiFeedbackTabSpokenWords]: 'Gesprochene Wörter',
  [IntlKeys.pitchesAiFeedbackTabLongPause]: 'Lange pause',
  [IntlKeys.pitchesAiFeedbackTabDoWords]: 'Do Wörter',
  [IntlKeys.pitchesAiFeedbackTabDontWords]: 'Don’t Wörter',
  [IntlKeys.pitchedFeedbackLowVoice]: 'Lautstärke',
  [IntlKeys.pitchedFeedbackLowVoiceCount]: 'mal zu leise',
  [IntlKeys.pitchedFeedbackFacialExpression]: 'Gesichtsausdrücke',
  [IntlKeys.pitchedFeedbackFacialExpressionSubText]:
    'Unten finden Sie Emotionen, die wir im Verlauf Ihres Tranings identifizieren konnten',
  [IntlKeys.pitchedFeedbackEmotionalAnalysis]: 'Emotionsanalyse',
  [IntlKeys.pitcheDeviceSettingsTitle]: 'Einstellung Mediengeräte',
  [IntlKeys.pitchesDeviceSettingsCamera]: 'Kamera',
  [IntlKeys.pitcheDeviceSettingsMicrophone]: 'Mikrofon',
  [IntlKeys.pitchesTrainingWelcome]: 'Willkommen zum HeyPractice! Training',
  [IntlKeys.pitchesTrainingStart]: 'Training starten',
  [IntlKeys.pitchesTrainingEmptyTextTaskAlert]: 'Textfeld sollte nicht leer sein',
  [IntlKeys.pitchesTrainingTextTaskLabel]: 'Ihre Antwort',
  [IntlKeys.pitchesTrainingAbort]: 'Abbrechen',
  [IntlKeys.pitchesTrainingStartTask]: 'Aufgabe starten',
  [IntlKeys.pitchesSlideSelectType]: 'Wählen Sie den Folientyp',
  [IntlKeys.pitchesSlideInfoSlide]: 'Folie',
  [IntlKeys.pitchesSlideInfoInfo]: 'Info',
  [IntlKeys.pitchesTeamTrainingStart]: 'Team Training',
  [IntlKeys.pitchesTeamTrainingSubline]: 'Das Team Training started in einem neuen Tab unter peterpitch.com',
  [IntlKeys.pitchesEvaluationCriteriaDescription]:
    'Unter dieser Box ist der in der Aufgabenstellung beschriebene Inhalt verschwommen dargestellt. Sobald Sie die Aufgabe starten, wird der Inhalt klar dargestellt, sodass Sie darauf reagieren können.',
  [IntlKeys.pitchesSlideValidationError]:
    'Die Folie enthält falsche Eingaben. Bitte überprüfen Sie die Eingaben und versuchen es erneut',
  [IntlKeys.pitchesSlideTrainingReviewLabel]: 'Beurteilung',
  [IntlKeys.pitchesSlideTrainingReviewCompletedTask]: 'Großartiger Job! Sie haben die Aufgabe abgeschlossen 🔥',
  [IntlKeys.pitchesSlideTrainingReviewEvaluateCompletedTask]: 'Bitte bewerten Sie jetzt Ihre Aufgabe!',
  [IntlKeys.pitchesSlideTrainigReviewCommentsInProgress]: 'Kommentare in Bearbeitung',
  [IntlKeys.pitchesSlideTrainingReviewCommentsLabel]: 'Kommentare',
  [IntlKeys.pitchesSlideTrainingReviewAiFeedbackLabel]: 'KI Feedback',
  [IntlKeys.pitchesSlideTrainingReviewAllAIAnalytics]: 'Alle KI-Analysen',
  [IntlKeys.pitchesSlideTrainingReviewShowAIAnalyticsDetails]: 'Zeige komplette Analyse',
  [IntlKeys.pitchesSlideTrainingLowSound]: 'Leiser Sound',
  [IntlKeys.pitchesSlideTrainingLowSoundChart]: 'Leiser Sound Graph',
  [IntlKeys.pitchesSlideTrainingFacialExpressionsChart]: 'Gesichtsausdrucksgraph',
  [IntlKeys.pitchesSlideTrainingWordsSpokenChart]: 'gesprochende Worte Graph',
  [IntlKeys.pitchesSlideTrainingWordsSpoken]: 'gesprochende Worte',
  [IntlKeys.pitchesSlideTrainingLongPauseChart]: 'lange Pausen Graph',
  [IntlKeys.pitchesSlideTrainingTotalPauses]: 'gesamte Pausenlänge',
  [IntlKeys.pitchesSlideTrainingEmotionalAnalyticsChart]: 'Emotionanalysegraph',
  [IntlKeys.pitchesTimerPanelTimeLimit]: 'Zeitlimit',
  [IntlKeys.pitchesSlideQuizType]: 'Quiz-Typ',
  [IntlKeys.pitchesSlideSelectQuizType]: 'Bevorzugtes Quiz wählen',
  [IntlKeys.pitchesSlideQuizChoiceSettings]: 'Frage-Einstellungen',
  [IntlKeys.pitchesSlideQuizChoiceSettingsOther]: '"Andere" option',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMultiple]: 'Mehrere Auswahlmöglichkeiten',
  [IntlKeys.pitchesSlideQuizChoiceSettingsRandomize]: 'Zufällige Reihenfolge',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMaxCharactersLabel]: 'Maximale Zeichenanzahl',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMaxCharactersTooltip]: 'Legen Sie die Anzahl an Zeichen fest',
  [IntlKeys.pitchesSlideQuizPreferences]: 'Quiz Einstellungen',
  [IntlKeys.pitchesSlideQuizTypePlaceholder]: 'Wählen Sie die Art des Quizzes',
  [IntlKeys.pitchesSlideQuizOptionalCorrectAnswers]:
    'Möchten Sie eine richtige Antwort für Ihr Quiz setzen? Das ist nicht erforderlich.',
  [IntlKeys.pitchesSlideQuizEnterAnswerPlaceholder]: 'Tragen Sie Ihre Antwort ein',
  [IntlKeys.pitchesSlideQuizSelectPair]: 'Wählen Sie das korrekte Paar',
  [IntlKeys.pitchesSlideQuizPairInstruction]:
    'Finden Sie die richtigen Paare aus den Optionen. Der Nutzer wird die richtigen Paare während des Trainings nicht sehen',
  [IntlKeys.pitchesSlidesQuizPairLabel]: 'Paar',
  [IntlKeys.pitchesSlidesQuizChoicesFirst]: 'Erster Teil der Optionen',
  [IntlKeys.pitchesSlidesQuizChoicesSecond]: 'Zweiter Teil der Optionen',
  [IntlKeys.pitchesSlidesBackgroundMedia]: 'Hintergrundmaterialien',
  [IntlKeys.pitchesSlidesBackgroundMediaDescription]:
    'Hintergrundmaterialien erscheinen dem Nutzer im Hintergrund während des Trainings',
  [IntlKeys.pitchesPitchNameAToZ]: 'Trainingsname A-Z',
  [IntlKeys.pitchesPitchNameZToA]: 'Trainingsname Z-A',
  [IntlKeys.pitchesSearchAPitch]: 'Nach einem Training suchen',
  [IntlKeys.pitchesRemovePitch]: 'Training entfernen',
  [IntlKeys.pitchesRemovePitchSubText]: 'Sind Sie sicher, dass Sie das Training entfernen möchten?',
  [IntlKeys.pitchesCreatePitch]: 'Training erstellen',
  [IntlKeys.pitchesAddPitch]: 'Training hinzufügen',
  [IntlKeys.pitchesPitchName]: 'Trainingsname',
  [IntlKeys.pitchesCover]: 'Titelbild',
  [IntlKeys.pitchesUpdatePitch]: 'Training updaten',
  [IntlKeys.pitchesNoPitchAvailable]: 'Sieht so aus, als hätten Sie noch keine Trainings',
  [IntlKeys.pitchesAddNewPitch]: 'Neues Training hinzufügen',
  [IntlKeys.pitchesFinishTraining]: 'Training beenden',
  [IntlKeys.pitchesProceedTraining]: 'Fortsetzen',
  [IntlKeys.pitchesCompletedHeading]: 'Großartiger Job! Sie haben alle Aufgaben abgeschlossen',
  [IntlKeys.pitchesCompletedDescription]:
    'Sie haben einen tollen Job gemacht! Sie können Ihre Ergebnisse und Aufnahmen jederzeit in Ihrem Dashboard ansehen. Hier können Sie Aufnahmen ebenfalls mit Ihrem Team teilen, um Feedback zu erhalten.',
  [IntlKeys.pitchesTrainingShare]: 'Teilen',
  [IntlKeys.pitchesSharePlaceholderRecords]: 'Wähle die Aufnahmen die Sie teilen wollen',
  [IntlKeys.pitchesSharePlaceholderUsers]: 'Geben Sie den Namen eines Kollegen ein',
  [IntlKeys.pitchesShareConfirmButton]: 'Bestätigen & Teilen',
  [IntlKeys.pitchesShareMessagesSuccess]: 'Aufnahmen erfolgreich mit ausgewählten Kollegen geteilt',
  [IntlKeys.pitchesShareMessagesErrorApi]: 'Ups! Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut',
  [IntlKeys.pitchesShareMessagesErrorUnknown]:
    'Fehlgeschlagen! Bitte versuchen Sie es später um Aufnahmen aus Ihrem Dashboard zu teilen',
  [IntlKeys.pitchesShareTitle]: 'Teilen Sie die Aufnahmen mit Ihren Kollegen',
  [IntlKeys.pitchTrainingEmptyComments]:
    'Kommentare die Sie hinzufügen werden hier erscheinen. Die Kommentare werden auch mit Ihrem Training eingereicht.',
  [IntlKeys.pitchesCopyToCourse]: 'Duplizieren',
  [IntlKeys.pitchesTrainingSubmitTask]: 'Weiter',
  [IntlKeys.pitchesTestTraining]: 'Test Training',
  [IntlKeys.pitchesTrainingLeavePagePromptMessage]:
    'Ihre Eingabe wurde nicht gespeichert. Möchten Sie die Seite wirklich verlassen?',
  [IntlKeys.pitchesTrainingCanNotBeStartedSlidesDoesNotExist]: 'Kann kein leeres Training starten',
  [IntlKeys.pitchesPartialTrainingPreviewTitle]: 'Willkommen zurück! Sie haben einen Teil des Trainings abgeschlossen',
  [IntlKeys.pitchesPartialTrainingPreviewCompletedTasks]: 'Abgeschlossene Aufgaben:',
  [IntlKeys.pitchesPartialTrainingPreviewContinueTraining]: 'Training fortsetzen',
  [IntlKeys.pitchesPartialTrainingStartOver]: 'Von Anfang an starten',
  [IntlKeys.pitchesPartialTrainingCompletedTitle]: 'Sie haben das Training erfolgreich beendet',
  [IntlKeys.pitchesPartialTrainingStartOverConfimTitle]: 'Sind Sie sicher, dass Sie das Training neu starten möchten?',
  [IntlKeys.pitchesPartialTrainingStartOverConfimText]:
    'Der Neustart des Trainings löscht nicht Ihre vorher getätigten Aufnahmen. Sie können neustarten um Ihre Fähigkeiten und Bewertungen zu verbessern',
  [IntlKeys.pitchesPartialTrainingStartOverFailed]: 'Von Beginn starten fehlgeschlagen',
  [IntlKeys.pitchAddPitchToCourses]: 'Training zu Kursen hinzufügen',
  [IntlKeys.pitchesImportPresentation]: 'Importiere',
  [IntlKeys.pitchesImportPresentationDescription]: 'Laden Sie Ihre eigenen Dateien vom Computer hoch',
  [IntlKeys.pitchesImportPresentationDocs]: 'Importieren Sie Ihre PDF, PowerPoint oder Keynote Präsentationen',
  [IntlKeys.pitchesTrainingCustomerReactionHeading]: 'Kunde sagt:',
  [IntlKeys.pitchesImportIntoSingleSlideLabel]: 'Einzelne Folie',
  [IntlKeys.pitchesImportIntoMultipleSlidesLabel]: 'Mehrere Folien',
  [IntlKeys.pitchesImportIntoSingleSlideSubText]: 'Importieren Sie in eine einzige Folie',
  [IntlKeys.pitchesImportIntoMultipleSlidesSubText]: 'Importieren Sie jede Seite in eigene Folie',
  [IntlKeys.pitchProgressTiltle]: 'Fortschritt',
  [IntlKeys.pitchProgressCompletedTasks]: ' Aufgaben erledigt',
  [IntlKeys.pitchRecordsEmptyRecords]: 'Keine Aufnahmen verfügbar',
  [IntlKeys.pitchesQuizCorrectAnswer]: 'Richtige Antwort',
  [IntlKeys.pitchesQuizIncorrectAnswer]: 'Falsche Antwort',
  [IntlKeys.pitchesQuizWaitingForReview]: 'Warte auf Bewertung',
  [IntlKeys.pitchesQuizCorrectAnswersQuantity]: 'Quiz sind korrekt',
  [IntlKeys.pitchesQuizEmptyQuizCard]: 'Keine Quiz wurden beantwortet',
  [IntlKeys.pitchesQuizYetToAnswer]: 'Muss noch beantwortet werden',
  [IntlKeys.pitchesPitchLeadershipBoard]: 'Pitch Bestenliste',
  [IntlKeys.pitchesQuizScaleChoiceStartValue]: 'Anfangswert',
  [IntlKeys.pitchesQuizScaleChoiceMaxLimit]: 'Maximalwert',
  [IntlKeys.pitchesQuizScaleChoiceStep]: 'Abstand',
  [IntlKeys.pitchesQuizAddChoice]: 'Auswahl hinzufügen',
  [IntlKeys.pitchesQuizTip]: 'Tipp',
  [IntlKeys.pitchesQuizPictureChoicePicture]: 'Bild',
  [IntlKeys.pitchesQuizReviewAllQuizzes]: 'Überprüfe alle Quiz',
  [IntlKeys.pitchesQuizIncorrectAnswers]: 'Antworten sind falsch',
  [IntlKeys.pitchesQuizQuizzesProgress]: 'Quiz Fortschritt:',
  [IntlKeys.pitchesQuizNumberOfTry]: 'Anzahl der Versuche:',
  [IntlKeys.pitchesVideoAudioTaskRetakeConfimTitle]: 'Sie Sie sicher, dass Sie die Aufgabe wiederholen möchtest?',
  [IntlKeys.pitchesVideoAudioTaskRetakeConfimText]: 'Das Wiederholen löscht die vorherige Aufnahme.',
  [IntlKeys.pitchesRecordYourselfOnVideo]: 'Videoaufzeichnung',
  [IntlKeys.pitchesRecordYourselfOnAudio]: 'Audioaufzeichnung',
  [IntlKeys.pitchesRightAnswerInText]: 'Antwort schreiben',
  [IntlKeys.pitchesFeedbackFacialExpressionOverallResult]: 'Gesamtergebnis',
  [IntlKeys.pitchesFeedbackFacialExpressionLiveResult]: 'Live-Ergebnis',
  [IntlKeys.pitchesFeedbackFacialExpressionHideLiveResultText]:
    'Spielen Sie das Video ab, um das Live-Diagramm zu sehen!',

  // Quiz
  [IntlKeys.pitchesTrainingTimeUp]: 'Zeit ist um',
  [IntlKeys.pitchesTrainingEnterYourAnswer]: 'Geben Sie Ihre Antwort ein',
  [IntlKeys.pitchesTrainingSelectYesOrNo]: 'Wählen Sie Ja oder Nein',
  [IntlKeys.pitchesTrainingMatchingListTip]:
    'Zuordnungsoptionen. Klicken Sie auf den Kreis an der Linie um falsche Verbindungen zu entfernen',
  [IntlKeys.pitchesTrainingSelectOneOption]: 'Wählen Sie eine Option',
  [IntlKeys.pitchesTrainingSelectSeveralOptions]: 'Wählen Sie mehrere Optionen',
  [IntlKeys.pitchesTrainingNextButton]: 'Weiter',
  [IntlKeys.pitchesTrainingOtherAnswer]: 'Andere antworten',
  [IntlKeys.pitchesTrainigResultWithWronAnswer]: '{wrongAnswers} von {totalAnswers} Quiz Antworten sind falsch',
  [IntlKeys.pitchesTrainingAllAnswersAreCorrect]: 'Sie haben alle Quiz korrekt beantwortet',
  [IntlKeys.pitchesTrainingRetryFailedQuizzes]: 'Versuchen Sie die Falschen nochmal',
  [IntlKeys.pitchesTrainigResultWithCorrectAnswer]: '{correctAnswers}/{totalAnswers} Antworten sind korrekt',
  [IntlKeys.pitchesTrainigResultWithPendingAnswer]:
    '{pendingAnswers}/{totalAnswers} Anworten werden vom Trainer überprüft',
  [IntlKeys.pitchesQuizOptionsMultipleChoice]: 'Multiple-Choice',
  [IntlKeys.pitchesQuizOptionsPictureChoice]: 'Bildauswahl',
  [IntlKeys.pitchesQuizOptionsMatchingList]: 'Paarungen',
  [IntlKeys.pitchesQuizOptionsYesOrNo]: 'Ja/Nein',
  [IntlKeys.pitchesQuizOptionsScaleChoice]: 'Skala',
  [IntlKeys.pitchesQuizOptionsShortText]: 'Kurztext',
  [IntlKeys.pitchesQuizShortTextMaxValueMoreThanZero]: 'Maximale Anzahl muss größer 0 sein',

  // Records
  [IntlKeys.recordsNotAvailable]: 'Diese Aufnahme ist nicht verfügbar',
  [IntlKeys.recordsAiFeedbackAuthorPutExtraCondition]:
    'Der Autor dieses Trainings hat einige Spezialbedingungen eingestellt, die sich zusätzlich zu den Bewertungskriterien auf die Punktzahl auswirken können.',
  [IntlKeys.recordsAiFeedbackGeneratedWithRealTimeSpeech]:
    'Das folgende Resultat wurde mit einer Echtzeit Sprachaufnahme während des Trainings  generiert',
  [IntlKeys.recordsPreviewPreviewComesHere]: 'Vorschau kommt hier:',
  [IntlKeys.recordsReviewTabSuggestionToRate]:
    'Bitte nutzen Sie das folgende Bewertungskriterium um die Performance des {authorFirstName} zu bewerten. Das wird {authorFirstName} helfen seine Fähigkeiten in bestimmten Bereichen zu stärken.',
  [IntlKeys.recordsTaskInfoUsefulTrainingMaterials]: 'Nützliche Trainingsmaterialien',
  [IntlKeys.recordsUserFeedbacksNoReviewAvailable]: 'Sorry! Bisher keine Rückmeldungen für das Training!',
  [IntlKeys.recordsSearchARecord]: 'Durchsuchen Sie die Aufnahmen',
  [IntlKeys.recordsRecordDetailsSubmitted]: 'Eingereicht:',
  [IntlKeys.recordsRecordDetailsTraining]: 'Training:',
  [IntlKeys.recordsEmptyRecordsSubTitle]: 'Sieht so aus, als wären noch keine Aufnahmen zur Beurteilung verfügbar',
  [IntlKeys.recordEmptyComments]: 'Keine Kommentare für diese Aufnahme gefunden.',
  [IntlKeys.recordSubmitted]: 'Aufnahem übermittelt',
  [IntlKeys.recordsNew]: 'Neue Aufnahmen',
  [IntlKeys.noRecordsAvailable]: 'Keine Benutzereingaben verfügbar',
  [IntlKeys.noRecordsAvailableForTask]: 'Für diese Aufgabe wurden keine Datensätze übermittelt',
  [IntlKeys.newRecords]: '{count, plural, =0 {keine neuen Aufnahmen} ein {# neue Aufnahme} other {# neue Aufnahmen}}',
  [IntlKeys.newTotalRecords]: 'Neue / Alle Aufnahmen',
  [IntlKeys.recordsFilterByWorkspace]: 'Nach Workspace filtern',

  // Quiz Submissions
  [IntlKeys.quizSubmissionReviewed]: 'Quizantwort wurde überprüft',
  [IntlKeys.quizSubmissionReviewRequired]: 'Überprüfung erforderlich',
  [IntlKeys.quizSubmissionCorrectAnswers]: 'Richtige Antworten',
  [IntlKeys.quizSubmissionNoAnswerYet]: 'Es wurde noch keine Antwort eingereicht',
  [IntlKeys.quizSubmissionIncorrectAnswer]: 'Falsche Antwort',
  [IntlKeys.quizSubmissionPendingEvaluation]: 'Überprüfung erforderlich, da keine richtige Antwort gegeben wurde',
  [IntlKeys.quizSubmissionCorrectAnswer]: 'Die Antwort ist richtig',
  [IntlKeys.quizSubmissionMarkAs]: 'Antwort markieren als:',
  [IntlKeys.quizSubmissionCorrect]: 'Richtig',
  [IntlKeys.quizSubmissionIncorrect]: 'Falsch',
  [IntlKeys.quizSubmissionNotYet]: 'Das Quiz wurde nicht beantwortet',
  [IntlKeys.quizSubmissionExpectedAnswer]: 'Erwartete Antwort',

  // Review
  [IntlKeys.commentsPlaceholder]: 'Schreiben Sie einen Kommentar',
  [IntlKeys.commentsCommentText]: 'Kommentar',

  // Settings
  [IntlKeys.settingsAccountSettingsAccountEmailTitle]: 'E-Mail',
  [IntlKeys.settingsAccountSettingsSetupNewPassword]: 'Neues Passwort erstellen',
  [IntlKeys.settingsAccountSettingsOldPassword]: 'Altes Passwort',
  [IntlKeys.settingsAccountSettingsEnterYourPassword]: 'Geben Sie Ihr Passwort ein',
  [IntlKeys.settingsAccountSettingsPasswordNotMatch]: 'Passwörter stimmen nicht überein',
  [IntlKeys.settingsAccountSettingsEnterNewPassword]: 'Neues Passwort eingeben',
  [IntlKeys.settingsNotificationSettingsNotification]: 'Benachrichtigung',
  [IntlKeys.settingsNotificationSettingsPitchNotifications]: 'Training Benachrichtigung',
  [IntlKeys.settingsNotificationSettingsNewCommentsFromCoach]: 'Neue Kommentare vom Coach',
  [IntlKeys.settingsNotificationSettingsFeedbackFromCoach]: 'Feedback vom Coach',
  [IntlKeys.settingsNotificationSettingsNewCommentsFromUsers]: 'Neue Kommentare von Benutzern',
  [IntlKeys.settingsNotificationSettingsFeedbackFromUsers]: 'Feedback der Benutzer',
  [IntlKeys.settingsNotificationSettingsPersonalNotifications]: 'Persönliche Benachrichtigung',
  [IntlKeys.settingsNotificationSettingsNewBadges]: 'Neue Abzeichen',
  [IntlKeys.settingsNotificationSettingsLeaderboardUpdates]: 'Updates der Bestenliste',
  [IntlKeys.settingsNotificationSettingsCommunityNotifications]: 'Community Benachrichtigungen',
  [IntlKeys.settingsProfileSettingsChangeProfilePhoto]: 'Ändere Profilfoto',
  [IntlKeys.settingsProfileSettingsUploadNewPhoto]: 'Laden Sie ein neues Foto hoch',
  [IntlKeys.settingsProfileSettingsPersonalInfo]: 'Persönliche Info',
  [IntlKeys.settingsProfileSettingsTimeAndLanguage]: 'Zeit und Sprache',
  [IntlKeys.settingsProfileSettingsTimeZone]: 'Zeitzone',
  [IntlKeys.settingsProfileSettingsRemoveProfilePhoto]: 'Profilbild entfernen',
  [IntlKeys.settingsProfileSettingsRemoveProfilePhotoSubText]:
    'Sind Sie sicher, dass Sie Ihr Profilbild entfernen wollen?',
  [IntlKeys.settingsProfile]: 'Profil',
  [IntlKeys.settingsProfileDescription]: 'Fügen Sie Ihre Informationen hinzu.',
  [IntlKeys.settingsAccount]: 'Account',
  [IntlKeys.settingsAccountDescription]: 'Bearbeiten Sie Ihre Accounteinstellungen und ändern Sie Ihr Passwort.',
  [IntlKeys.settingsNotifications]: 'Benachrichtigungen',
  [IntlKeys.settingsNotificationsDescription]: 'Richten Sie Benachrichtigungen von HeyPractice! ein',
  [IntlKeys.settingsCustomization]: 'Customization',
  [IntlKeys.settingsCustomizationDescription]: 'Passen Sie das Aussehen von HeyPractice für Ihre Workspaces an',
  [IntlKeys.settingsDomain]: 'Domain',
  [IntlKeys.settingsDomainTitle]: 'Persönliche Domain',
  [IntlKeys.settingsDomainAppearance]: 'Das Aussehen',
  [IntlKeys.settingsDomainLoginTitle]: 'Logintitel',
  [IntlKeys.settingsDomainLoginDescription]: 'Loginbeschreibung',
  [IntlKeys.settingsDomainLogo]: 'Logo',
  [IntlKeys.settingsDomainLogoDefault]: 'Default Logo',
  [IntlKeys.settingsDomainImage]: 'Login Bild',
  [IntlKeys.settingsDomainImageDefault]: 'Default Bild',
  [IntlKeys.settingsDomainThemeCustom]: 'Custom Theme',
  [IntlKeys.settingsDomainThemeActiveColor]: 'Akzent Farbe',
  [IntlKeys.settingsDomainThemeBGColor]: 'Hintergrund Farbe',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Das Passwort sollte nicht mehr als 20 Zeichen haben',
  [IntlKeys.domainNameValidationError]: 'Domainname ist ungültig',
  [IntlKeys.domainNameUpdateSuccess]: 'Domainname erfolgreich geupdated',
  [IntlKeys.domainNameUpdateFailure]: 'Entschuldigung! Domainname konnte nicht geupdated werden',

  // Teams
  [IntlKeys.teamsCreateNewTeam]: 'Erstelle neues Team',
  [IntlKeys.teamsSearchAndAddMembers]: 'Suchen und fügen Sie Mitglieder hinzu',
  [IntlKeys.teamsSelectMembers]: 'Wähle Mitglieder aus',
  [IntlKeys.teamsNoMemberAvailable]: 'Sieht so aus, als hätten Sie noch keine Mitglieder',
  [IntlKeys.teamsYouCanAddNewMember]: 'Sie können einfach ein neues Mitglied hinzufügen',
  [IntlKeys.teamsConnectedCourses]: 'Verbundene Kurse',
  [IntlKeys.teamsFilterTeamNameAtoZ]: 'Team A-Z',
  [IntlKeys.teamsFilterTeamNameZtoA]: 'Team Z-A',
  [IntlKeys.teamsFilterNumberOfMembersAsc]: 'Anzahl der Mitglieder ↓',
  [IntlKeys.teamsFilterNumberOfMembersDesc]: 'Anzahl der Mitglieder ↑',
  [IntlKeys.teamFilterCreatedAtDesc]: 'Erstellungsdatum ↓',
  [IntlKeys.teamFilterCreatedAtAsc]: 'Erstellungsdatum ↑',
  [IntlKeys.teamsEditTeam]: 'Team bearbeiten',
  [IntlKeys.teamsRemoveTeam]: 'Team entfernen',
  [IntlKeys.teamsRemoveTeamSubText]: 'Sind Sie sicher, dass Sie das Team entfernen möchten?',
  [IntlKeys.teamsMemeberMemberNameAtoZ]: 'Mitglieder A-Z',
  [IntlKeys.teamsMemeberMemberNameZtoA]: 'Mitglieder Z-A',
  [IntlKeys.teamsMemeberMemberProgressAsc]: 'Mitglied Fortschritt ↑',
  [IntlKeys.teamsMemeberMemberProgressDesc]: 'Mitglied Fortschritt ↓',
  [IntlKeys.teamsMemeberMemberNewRecords]: 'Letzte Aktivität des Mitglieds',
  [IntlKeys.teamsMemeberMemberOldRecords]: 'Mitglied älteste Aktivität',
  [IntlKeys.teamsSearchAMember]: 'Suche ein Mitglied',
  [IntlKeys.teamsDetachFromTeam]: 'Aus dem Team entfernen',
  [IntlKeys.teamsRemoveMemberTitle]: 'Mitglied löschen',
  [IntlKeys.teamsRemoveMemberSubText]: 'Sind Sie sicher das Sie das Mitglied löschen möchten?',

  // User Management
  [IntlKeys.usersCreateFulfilled]: 'Nutzer erfolgreich angelegt',
  [IntlKeys.usersInvitationFulfilled]: 'Einladungen wurden erfolgreich an die E-Mail Aressen gesendet',
  [IntlKeys.usersInviteUser]: 'Laden Sie Nutzer ein',
  [IntlKeys.usersCreateUser]: 'Nutzer erstellen',
  [IntlKeys.usersCreateUserModal]: 'Erstelle Nutzerdialog',
  [IntlKeys.usersUploadCsv]: 'Importieren Sie Nutzer per CSV / JSON',
  [IntlKeys.usersNameAtoZ]: 'Nutzername A-Z',
  [IntlKeys.usersNameZtoA]: 'Nutzername Z-A',
  [IntlKeys.usersProgressAsc]: 'Nutzerfortschritt ↓',
  [IntlKeys.usersProgressDesc]: 'Nutzerfortschritt ↑',
  [IntlKeys.usersActivityAsc]: 'Nutzeraktivität ↓',
  [IntlKeys.usersActivityDesc]: 'Nutzeraktivität ↑',
  [IntlKeys.usersOldestUsers]: 'Älteste Nutzer',
  [IntlKeys.usersRecentlyAdded]: 'Kürzlich hinzugefügt',
  [IntlKeys.usersInviteNewMembers]: 'Neue Mitglieder einladen',
  [IntlKeys.usersWayOfMembersCreatingAccout]:
    'Mitglieder werden einen Account mit der versendeten Email oder dem Einladungslink erstellen',
  [IntlKeys.usersDelete]: 'Nuzer löschen',
  [IntlKeys.usersDeleteSubline]: 'Sind Sie sicher, dass Sie den Nutzer entfernen möchten?',
  [IntlKeys.usersDeactivate]: 'Deaktivieren',
  [IntlKeys.usersResendPassword]: 'Passwort erneut senden',
  [IntlKeys.usersArchive]: 'Nutzer archivieren',
  [IntlKeys.usersUnArchive]: 'Nutzer wiederherstelln',
  [IntlKeys.usersArchiveSubline]: 'Sind Sie sicher, dass Sie den Nutzer archivieren möchten?',
  [IntlKeys.usersArchiveDescription]:
    'Archivierte Nutzer werden nicht länger auf HeyPractice zugreifen können. Aber die Aufnahmen werden weiter verfügbar sein. Der Nutzer kann wiederhergestellt werden um seinen Zugriff zurückzuerhalten.',
  [IntlKeys.usersUnArchiveSubline]: 'Sind Sie sicher, dass Sie den Nutzer wiederherstellen möchten?',
  [IntlKeys.usersDeleteDescription]:
    'Wenn Sie einen Nutzer löschen werden alle seine persönlichen Daten gelöscht das beinhaltet seine Aufnahmen, Reviews und Statistiken',
  [IntlKeys.usersEditUser]: 'Nutzer bearbeiten',
  [IntlKeys.usersActive]: 'Aktive Nutzer',
  [IntlKeys.invitedBy]: 'Eingeladen von',
  [IntlKeys.usersRevokeInvitations]: 'Einladungen widerrufen',
  [IntlKeys.usersRevokeInvitationsConfimTitle]: 'Sind Sie sicher, dass Sie die Einladungen widerrufen möchten?',

  // Workspace
  [IntlKeys.workspaceAdd]: 'Workspace hinzufügen',
  [IntlKeys.workspaceEdit]: 'Workspace bearbeiten',
  [IntlKeys.workspaceName]: 'Workspacename',
  [IntlKeys.workspaceTabsUsersManagement]: 'Nutzerverwaltung',
  [IntlKeys.workspaceTabsUsersManagementDescription]: 'Verwalte Mitglieder innerhalb des Workspaces',
  [IntlKeys.workspaceTabsBilling]: 'Abrechnung',
  [IntlKeys.workspaceTabsBillingDescription]: 'Zahlungsdetails einrichten',
  [IntlKeys.workspaceSearch]: 'Workspace suchen',
  [IntlKeys.workspaceAttachCoursesToWorkspace]: 'Kurse zu Workspaces hinzufügen',
  [IntlKeys.workspaceClearSelection]: 'Auswahl zurücksetzen',
  [IntlKeys.workspaceNameAToZ]: 'Workspacename A-Z',
  [IntlKeys.workspaceNameZToA]: 'Workspacename Z-A',
  [IntlKeys.workspaceUserCountAsc]: 'Nutzeranzahl ↑',
  [IntlKeys.workspaceUserCountDesc]: 'Nutzeranzahl ↓',
  [IntlKeys.workspaceLastUpdates]: 'Letzte Updates',
  [IntlKeys.workspaceOldUpdates]: 'Alte Updates',
  //
  [IntlKeys.workspaceRemoveWorkspace]: 'Workspace entfernen',
  [IntlKeys.workspaceArchiveWorkspace]: 'Workspace archivieren',
  [IntlKeys.workspaceUnArchiveWorkspace]: 'Workspace wiederherstellen',
  [IntlKeys.workspaceRemovingWorkspaceConfirmQuestion]: 'Möchten Sie den Workspace wirklich entfernen?',
  [IntlKeys.workspaceRemovingWorkspaceDescription]:
    'Alle mit dem Workspace verbundenen Informationen werden gelöscht, einschließlich der mit den Kursen verbundenen Benutzeraufzeichnungen und Ergebnisse',
  [IntlKeys.workspaceArchivingWorkspaceConfirmQuestion]: 'Möchten Sie den Workspace wirklich archivieren?',
  [IntlKeys.workspaceUnArchivingWorkspaceConfirmQuestion]: 'Möchten Sie den Workspace wirklich wiederherstellen?',
  [IntlKeys.workspaceConfirmationPhrase]: 'Bitte geben Sie den Workspacesnamen ein, um den Löschvorgang zu bestätigen',
  [IntlKeys.workspaceCreateSuccess]: 'Workspace erfolgreich erstellt',
  [IntlKeys.workspaceCreateFailure]: 'Erstellen des Workspaces fehlgeschlagen',
  [IntlKeys.workspaceUpdateSuccess]: 'Workspace erfolgreich geupdated',
  [IntlKeys.workspaceUpdateFailure]: 'Updaten des Workspaces fehlgeschlagen',
  [IntlKeys.workspaceDeletingSuccess]: 'Workspace erfolgreich gelöscht',
  [IntlKeys.workspaceDeletingFailure]: 'Löschen des Workspaces fehlgeschlagen',
  [IntlKeys.workspaceAll]: 'Alle Workspaces',
  [IntlKeys.workspaceActive]: 'Aktive Workspaces',
  [IntlKeys.workspaceArchived]: 'Archivierte Workspaces',
  [IntlKeys.workspaceLead]: 'Lead Workspaces',

  //Mapping
  [IntlKeys.commentFromCoach]: 'hat Ihre Aufnahme kommentiert.',
  [IntlKeys.commentFromUser]: 'hat Ihre Aufnahme kommentiert.',
  [IntlKeys.feedbackFromCoach]: 'hat Ihnen Feedback gegeben.',
  [IntlKeys.feedbackFromUser]: 'hat Ihnen Feedback gegeben',
  [IntlKeys.newBadges]: 'hat neue Badges erhalten',
  [IntlKeys.leaderboardUpdated]: 'Leaderboard wurde aktualisiert',
  [IntlKeys.shareContentFile]: 'Datei geteilt',
  [IntlKeys.shareContentFolder]: 'Ordner geteilt',
  [IntlKeys.shareContentList]: 'Liste geteilt',
  [IntlKeys.shareRecord]: 'Aufnahme geteilt',
  [IntlKeys.newCoursePublished]: 'hat einen neuen Kurs veröffentlicht',
  [IntlKeys.userAttachedToCourse]: 'hat einen neuen Kurs zu Ihrer Liste hinzugefügt',

  //Face expression mapping
  [IntlKeys.happyFace]: 'Glücklichkeit erkannt',
  [IntlKeys.sadFace]: 'Sie scheinen traurig zu sein.',
  [IntlKeys.neutralFace]: 'Sie sehen neutral aus.',
  [IntlKeys.disgustFace]: 'Sind Sie angewiedert?',
  [IntlKeys.fearFace]: 'Seien Sie nicht ängslich!',
  [IntlKeys.angryFace]: 'Oh, oh Wut erkannt.',
  [IntlKeys.surpriseFace]: 'Überrascht?',

  [IntlKeys.happy]: 'Glücklich',
  [IntlKeys.sad]: 'Traurig',
  [IntlKeys.neutral]: 'Neutral',
  [IntlKeys.disgust]: 'Angewiedert',
  [IntlKeys.fear]: 'Ängstlich',
  [IntlKeys.angry]: 'Sauer.',
  [IntlKeys.surprise]: 'Überrascht',
  [IntlKeys.confused]: 'Verwirrt',
  [IntlKeys.relaxed]: 'Entspannt',
  [IntlKeys.bored]: 'Gelangweilt',
  [IntlKeys.anger]: 'Wut',
  [IntlKeys.astonished]: 'Erstaunt',
  [IntlKeys.calm]: 'Entspannt',
  [IntlKeys.delight]: 'Freude',
  [IntlKeys.laugh]: 'Lachen',
  [IntlKeys.scream]: 'Schreien',
  [IntlKeys.smile]: 'Lächeln',
  [IntlKeys.shock]: 'Schock',

  [IntlKeys.competitive]: 'Wetteifernd',
  [IntlKeys.composed]: 'Gefasst',
  [IntlKeys.cooperative]: 'Kooperative',
  [IntlKeys.directing]: 'Gesprächsführend',
  [IntlKeys.empathic]: 'Empatisch',
  [IntlKeys.formal]: 'Formal',
  [IntlKeys.friendly]: 'Freundlich',
  [IntlKeys.goal_oriented]: 'Zielorientiert',
  [IntlKeys.positive]: 'Positiv',
  [IntlKeys.self_confident]: 'Selbstbewusst',
  [IntlKeys.professional]: 'Professionel',

  // Notifications
  [IntlKeys.notificationsMarkAllAsRead]: 'Alle als gelesen markieren',
  [IntlKeys.notificationsNoNotificationAvailable]: 'Keine Benachrichtigungen verfügbar',
};
export default translations;
