import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { merge } from 'lodash';
// types
import { CommentType } from 'types/slides/contract';
//
import {
  getRecordsAction,
  getRecordAction,
  getRecordAnalyses,
  changeRecordsRequestParamsAction,
  getCommentsAction,
  getCoachRecordAction,
  getCoachCommentsAction,
  getBEFacialResultsAction,
} from './actions';
import { RecordType, RecordsSortConfigs, RecordAnalysis, BEFacialAnalyses } from './entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isCommentsLoading: false,
  },
  filters: {} as Record<string, string>,
  sort: RecordsSortConfigs['updatedAtDesc'],
  pagination: {
    page: 1,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  records: {} as Record<string, { isLoading?: boolean; data?: RecordType }>,
  resource: null as RecordType | null,
  resources: [] as RecordType[],
  recordAnalise: null as RecordAnalysis | null,
  bEFacialAnalyses: null as BEFacialAnalyses | null,
  comments: [] as CommentType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getRecordsAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getRecordsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      const { data, ...pagination } = payload;

      draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getRecordsAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(changeRecordsRequestParamsAction, (draft, { payload }) => {
      //
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = initialState.resources;
      draft.pagination = initialState.pagination;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(
      getRecordAction.pending,
      (
        draft,
        {
          meta: {
            arg: { params },
          },
        },
      ) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.records[params?.id as number] = { isLoading: false, data: undefined };
      },
    )
    .addCase(getRecordAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resource = payload;
      draft.records[payload.id] = { isLoading: false, data: payload };
    })
    .addCase(
      getRecordAction.rejected,
      (
        draft,
        {
          meta: {
            arg: { params },
          },
        },
      ) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.records[params?.id as number] = { isLoading: false, data: undefined };
      },
    )
    .addCase(
      getCoachRecordAction.pending,
      (
        draft,
        {
          meta: {
            arg: { params },
          },
        },
      ) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.records[params?.id as number] = { isLoading: false, data: undefined };
      },
    )
    .addCase(getCoachRecordAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resource = payload;
      draft.records[payload.id] = { isLoading: false, data: payload };
    })
    .addCase(
      getCoachRecordAction.rejected,
      (
        draft,
        {
          meta: {
            arg: { params },
          },
        },
      ) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.records[params?.id as number] = { isLoading: false, data: undefined };
      },
    )
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getRecordAnalyses.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getRecordAnalyses.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.recordAnalise = payload;
    })
    .addCase(getRecordAnalyses.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getBEFacialResultsAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getBEFacialResultsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.bEFacialAnalyses = payload;
    })
    .addCase(getBEFacialResultsAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCommentsAction.pending, (draft) => {
      draft.meta.isCommentsLoading = true;
    })
    .addCase(getCommentsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isCommentsLoading = false;

      draft.comments = payload;
    })
    .addCase(getCommentsAction.rejected, (draft) => {
      draft.meta.isCommentsLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCoachCommentsAction.pending, (draft) => {
      draft.meta.isCommentsLoading = true;
    })
    .addCase(getCoachCommentsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isCommentsLoading = false;

      draft.comments = payload;
    })
    .addCase(getCoachCommentsAction.rejected, (draft) => {
      draft.meta.isCommentsLoading = false;
    });
});

export const recordsReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
