// utils
import { ResourcesResponse } from 'lib/http';
// types
import { SortConfigType } from 'types/common';
import { UserType } from 'types/users';
import { ReviewScoreType, SlideTaskTypeEnum } from 'types/slides';
import { CommentPayloadType, SlideResponse } from 'types/slides/contract';
import { CommentType } from 'types/slides/contract';
import { FaceAnalysisResponse } from '../pitches/base/entities';

export interface PauseAnalysis {
  id: number;
  from: number;
  to: number;
}

export type WordAnalyses = {
  type: 'do' | "don't";
  word: string;
  id: number;
  confidence: number;
  timestamp: number;
};

interface Soundlevel {
  from: number;
  to: number;
  type: string;
}
interface FacialAnalysis {
  payload: {
    avgEmotionLevels: Record<string, number>;
    emotionLevelsAtTimestamp: Record<
      string,
      {
        name: string;
        probability: number;
      }
    >;
  };
}
type EmotionScore = {
  score: number;
  percentile: number;
};

interface EmotionalAnalysis {
  payload: Record<string, EmotionScore>;
}

export interface BEFacialAnalyses {
  id: number;
  recordAnalysisId: number;
  payload: {
    avgEmotionLevels: Record<string, number>;
    emotionLevelsAtTimestamp: Record<
      number,
      {
        name: string;
        probability: number;
      }
    >;
    avgLevelsAtTimestamp: Record<number, Record<string, number>>;
  };
}

export interface RecordAnalyseData {
  id: string;
  emotionAnalysis: EmotionalAnalysis | null;
  facialAnalysis: FacialAnalysis | null;
  beyondEmotionAnalysis: BEFacialAnalyses | null;
  pauseAnalyses: PauseAnalysis[];
  soundAnalyses: Soundlevel[];
  speechSpeed: number;
  soundLevels: number[];
  wordAnalyses: WordAnalyses[];
}

export type RecordType = {
  id: number;
  recordText: string;
  recordUrl: string;
  createdAt: string;
  score: number;
  updatedAt: string;
  slideId: number;
  status: string;
  author: UserType;
  slide: SlideResponse;
  cover: string;
  duration: number;
  recordAnalysis: RecordAnalyseData;
  comments?: CommentType[];
};

export type UserRecordType = {
  avatar: string;
  companyName: string;
  cover?: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  id: number;
  jobTitle: string;
  language: string;
  newRecordsCount: number;
  records: RecordType[];
  role: string;
  status: string;
  totalDuration: number;
  updatedAt: string;
  recordSubmissionSince: string;
  recordLastSubmission: string;
};

export type UserPitchType = {
  authorId: number;
  courseId: number;
  cover: string;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  newRecordCount: number;
  pitchId: number;
  totalDuration: number;
  updatedAt: string;
};

export type RecordsResponse = ResourcesResponse<RecordType>;

const uSortConfigs = {
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
};

export type SpeechRecognitionResult = {
  message_type: string;
  audio_start: number;
  audio_end: number;
  confidence: number;
  text: string;
  words: [
    {
      confidence: number;
      text: string;
      start: number;
      end: number;
    },
  ];
  created: string;
  punctuated: boolean;
  text_formatted: boolean;
};

export type CreateRecordParams = {
  slideId: number;
  taskType: SlideTaskTypeEnum;
  recordText?: string;
  videoResult?: Blob;
  audioResult?: Blob;
  duration: number;
  transcriptionReport: SpeechAnaliseResult | undefined;
  soundLevels: number[];
  reviewScores?: {
    feedback?: string;
    reviewScores?: ReviewScoreType[];
  };
  beyondEmotionAnalysis: BEFacialAnalyses | null;
  comments?: CommentPayloadType[];
};

export type WordAlternative = {
  alternatives: {
    word: string;
    confidence: number;
  }[];
  end_time: number;
  start_time: number;
};

export type KeywordResult = {
  confidence: number;
  end_time: number;
  normalized_text: string;
  start_time: number;
};

export type TranscriptResults = {
  final?: boolean;
  result_index: number;
  results: TranscriptionResult[];
};

export type TranscriptionResult = {
  final: boolean;
  alternatives: Array<{
    transcript: string;
    confidence: number;
    timestamps: Array<[string, number, number]>;
  }>;
  keywords_result: Partial<KeywordResult>;
  word_alternatives: WordAlternative[];
};

export type SpeechAnaliseResult = {
  transcriptResults: TranscriptResults[];
  audioMetrics: Record<string, unknown>;
  soundLevels: number[];
  facialRecognitionResults?: FaceAnalysisResponse[];
};

export type CreateSpeechAnaliseParams = {
  slideId: number;
  transcriptionReport: SpeechAnaliseResult;
};

export type AnalyzedWordType = {
  word: string;
  timestamp: number;
  type: string;
  id: number;
};

export type RecordAnalyseResponseType = {
  emotionAnalysis: EmotionalAnalysis | null;
  facialAnalysis: FacialAnalysis | null;
  beyondEmotionAnalysis: BEFacialAnalyses | null;
  soundLevels?: number[];
  pauseAnalyses: PauseAnalysis[];
  wordAnalyses: AnalyzedWordType[];
  speechSpeed: number;
  soundAnalyses: Soundlevel[];

  transcriptionReport?: {
    report: SpeechAnaliseResult;
  };
};

export type RecordAnalysis = Omit<RecordAnalyseResponseType, 'wordAnalyses'> & {
  wordAnalyses: {
    doWords: AnalyzedWordType[];
    dontWords: AnalyzedWordType[];
  };
};

export type ReviewPayloadType = {
  recordId: number;
  feedback: string;
  reviewScores: {
    evaluationCriteriaId: number;
    score: number;
  }[];
};

export type CreateQuizAnswersPayload = {
  quizId?: number;
  payload: unknown;
  isFailed: boolean;
  reason: string;
};

export type CreateQuizAnswersResponse = {};

export const RecordsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
