import AccessControlListsScheme from 'lib/acl';
//
import { store } from 'portal/workspace/domain/store';
import { authRolesSelector, authUserSelector } from 'portal/workspace/domain/auth/selectors';
//
import { UserRoles } from 'types/users';
import { PitchStatusEnum, PitchType } from 'types/pitches';
import { isScormPortal } from 'utils/portalCheck';

class AccessControlLists extends AccessControlListsScheme {
  get me() {
    return authUserSelector(store.getState());
  }
  get isCoachInWorkspaceAdminRole() {
    const { realRole, virtualRole } = authRolesSelector(store.getState());
    return realRole === UserRoles.Coach && virtualRole === UserRoles.WorkspaceAdmin;
  }

  canTestTraining = (pitch: PitchType): boolean => {
    const { virtualRole } = authRolesSelector(store.getState());
    return this.canUpdatePitch(pitch) && virtualRole !== UserRoles.Coach;
  };

  canDoTeamTraining = (pitch: PitchType): boolean => {
    return (
      pitch.status === PitchStatusEnum.published && (this.canUpdateCourseList() || this.isCoachInWorkspaceAdminRole)
    );
  };

  canViewPitchRecords = (): boolean => {
    return (!isScormPortal && ACL.canUpdateCourseList()) || this.isCoachInWorkspaceAdminRole;
  };

  canFavouriteCourse = (): boolean => {
    const { realRole } = authRolesSelector(store.getState());
    return realRole !== UserRoles.Coach;
  };
}

const ACL = new AccessControlLists();

export default ACL;
