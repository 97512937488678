import React from 'react';
import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

export const colors = {
  primary: '#129AFE',
  secondary: '#5C6485',
  white: '#FFF',
  oxfordBlue: '#000D40',
  lightBlue: '#F2F9FF',
  stroke: '#E9EEF2',
  gray: '#969BB0',
  lightGray: '#FAFBFC',
  disabledGray: '#D1D6EA',
  red: '#F72048',
  error: '#d32f2f',
  warning: '#FFB432',
  success: '#25d02e',
  black: '#000',
  lightYellow: '#FFF9EF',
  lightGreen: '#20F79E',
  codeBlock: '#202124',
  randomColor: '#' + (((1 << 24) * Math.random()) | 0).toString(16),
};

const fonts = {
  gilmer: '"Gilmer-Font", serif',
  hando: '"Hando-Trial", serif',
};

const breakpoints = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const media = (direction: 'up' | 'down') =>
  (Object.keys(breakpoints) as (keyof typeof breakpoints)[]).reduce((acc, label) => {
    acc[label] = `@media (${direction === 'down' ? 'max' : 'min'}-width: ${breakpoints[label]}px)`;
    return acc;
  }, {} as { [K in keyof typeof breakpoints]: string });

const theme = {
  breakpoints,
  media: {
    up: media('up'),
    down: media('down'),
  },
  colors,
  fonts,
  dimensions: {
    headerHeight: 80,
    filterBarHeight: 80,
    sidebarWidth: 180,
    //
    pagePadding: '32px 40px',
    pagePaddingX: 40,
    pagePaddingY: 32,
    //
  },
  mixins: {
    centered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    stretched: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fullsizeAbsolute: {
      position: 'absolute' as const,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
};

export type CustomThemeType = typeof theme;

export type MainTheme = Theme & CustomThemeType;

export const mainTheme = createTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },

    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            zIndex: 1250,
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(146, 146, 146, 0.15)',
          },
        },
      },

      MuiAvatar: {
        defaultProps: {
          children: <UserIcon width="35%" height="100%" />,
        },
        styleOverrides: {
          root: {
            backgroundColor: colors.lightBlue,
          },
        },
      },

      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },

    typography: {
      fontFamily: [
        //
        theme.fonts.gilmer,
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
  theme,
) as MainTheme;
